import axios from 'axios';
import { getSlugQuery, getTokenHeader } from './utils';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
const http = axios.create({
  baseURL,
});
http.interceptors.response.use((response) => response.data);

class ApiStore {
  constructor(basePath) {
    this.baseURL = baseURL;
    this.basePath = basePath;
    this.http = http;
  }

  get = (idOrQueryParams, queryParams) => {
    const encodeQueryParams = args => {
      if (!args) return ''
      const { params, limit, offset, order } = args
      let qp = `&params=${params && encodeURIComponent(JSON.stringify(params))}`;
      if (limit) qp += `&limit=${limit}`;
      if (offset) qp += `&offset=${offset}`;
      if (order) qp += `&order=${order}`;
      return qp;
    }

    let path = this.basePath;
    if (idOrQueryParams && typeof idOrQueryParams === 'string') {
      path += `/${idOrQueryParams}${getSlugQuery()}${queryParams ? encodeQueryParams(queryParams) : '&order=-updatedAt'}`
    } else {
      path += `${getSlugQuery()}${idOrQueryParams ? encodeQueryParams(idOrQueryParams) : '&order=-updatedAt'}`
    }
    return http.get(path, getTokenHeader());
  }

  create = (doc) => http.post(`${this.basePath}${getSlugQuery()}`, doc, getTokenHeader())

  replace = (id, doc) => http.put(`${this.basePath}/${id}${getSlugQuery()}`, doc, getTokenHeader())

  delete = (id) => http.delete(`${this.basePath}/${id}${getSlugQuery()}`, getTokenHeader())
}

export default ApiStore;
