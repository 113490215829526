import React, { Component, Fragment } from 'react'
import Form from '../../components/Form'
import BottomBar from '../../components/BottomBar'
import ConfigService from '../../services/config'
import { required, maxLength, isEmail } from '../../services/validation'
import { List } from 'immutable'
import { Layout, Button, Icon, Collapse, message } from 'antd'
import queryString from 'query-string';

const { Input } = Form
const { Panel } = Collapse

export default class Config extends Component {
  state = {
    config: null
  }
  configService = new ConfigService()

  componentWillMount() {
    this.configService.getConfig().then(config => {
      if (config && Object.keys(config).length > 0) {
        this.setState({
          config
        })
      } else {
        const slug = queryString.parse(window.location.search).slug
        this.setState({
          config: {
            ClientSlug: slug
          }
        })
      }
    })
  }

  handleSubmitSuccess = values => {
    this.configService.setConfig(values).then(config => {
      this.setState({
        config
      })
      message.success("la configuration a été mise à jour")
    })
  }

  handleSubmitFailure = (errors) => {
    message.error("Erreur, vérifiez les champs");
    if (errors.get(List([]))) {
      message.error(errors.get(List([])))
    }
  }

  render = () => {
    const { config } = this.state;
    if (!config) return null;
    const { senderSms, senderEmail, from } = config;
    config.contactFieldsMapping = config.contactFieldsMapping || {};

    return (
      <Layout>
        <div className="Content-header mb-2">
          <h1><Icon className="mr-1" type="setting" />Configuration</h1>
        </div>

        <Form
          className="mt-2"
          value={config}
          onSubmitSuccess={this.handleSubmitSuccess}
          onSubmitFailure={this.handleSubmitFailure}
          rules={[{
            path: ['company'],
            validators: [required]
          }, {
            path: ['addressLine1'],
            validators: [required]
          }, {
            path: ['addressPostalCode'],
            validators: [required]
          }, {
            path: ['addressCity'],
            validators: [required]
          }, {
            path: ['senderSms'],
            validators: [ maxLength(11) ]
          }, {
            path: ['from'],
            validators: [ isEmail ]
          }]}
          submitRules={[]}>
          {form => (
            <Fragment>
              <Collapse defaultActiveKey={['1']} className="mt-3">
                <Panel key="1" header={<strong>Informations de contact</strong>}>
                  <Input
                  path={['company']}
                  label="Nom de l'organisation *"
                  form={form} />
                  <Input
                  path={['addressLine1']}
                  label="Adresse *"
                  form={form} />
                  <Input
                  path={['addressLine2']}
                  label="Complément d'adresse"
                  form={form} />
                  <Input
                  path={['addressPostalCode']}
                  label="Code postal *"
                  form={form} />
                  <Input
                  path={['addressCity']}
                  label="Ville *"
                  form={form} />
                </Panel>
              </Collapse>

              <Collapse defaultActiveKey={['1']} className="mt-3">
                <Panel key="1" header={<strong>Configuration des emails</strong>}>
                  <Input
                  path={['senderEmail']}
                  label="Nom de l'expéditeur par défaut"
                  form={form} />
                  <Input
                  path={['from']}
                  label="Email de l'expéditeur par défaut"
                  form={form}
                  disabled
                  />
                </Panel>
              </Collapse>

              <Collapse defaultActiveKey={['1']} className="mt-3">
                <Panel key="1" header={<strong>Configuration des SMS</strong>}>
                  <Input
                    path={['senderSms']}
                    label="Nom de l'expéditeur SMS par défaut"
                    form={form} />
                </Panel>
              </Collapse>

              <Collapse defaultActiveKey={['1']} className="mt-3">
                <Panel key="1" header={<strong>Configuration des champs supplémentaires des contacts</strong>}>
                  <Input
                    path={['contactFieldsMapping', 'text1']}
                    label="Label pour le champ supplémentaire texte 1"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'text2']}
                    label="Label pour le champ supplémentaire texte 2"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'text3']}
                    label="Label pour le champ supplémentaire texte 3"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'text4']}
                    label="Label pour le champ supplémentaire texte 4"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'text5']}
                    label="Label pour le champ supplémentaire texte 5"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'float1']}
                    label="Label pour le champ supplémentaire nombre 1"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'float2']}
                    label="Label pour le champ supplémentaire nombre 2"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'float3']}
                    label="Label pour le champ supplémentaire nombre 3"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'date1']}
                    label="Label pour le champ supplémentaire date 1"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'date2']}
                    label="Label pour le champ supplémentaire date 2"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'date3']}
                    label="Label pour le champ supplémentaire date 3"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'date4']}
                    label="Label pour le champ supplémentaire date 4"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'date5']}
                    label="Label pour le champ supplémentaire date 5"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'boolean1']}
                    label="Label pour le champ supplémentaire case à cocher 1"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'boolean2']}
                    label="Label pour le champ supplémentaire case à cocher 2"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'boolean3']}
                    label="Label pour le champ supplémentaire case à cocher 3"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'boolean4']}
                    label="Label pour le champ supplémentaire case à cocher 4"
                    form={form} />
                  <Input
                    path={['contactFieldsMapping', 'boolean5']}
                    label="Label pour le champ supplémentaire case à cocher 5"
                    form={form} />
                </Panel>
              </Collapse>

              <BottomBar
                right={
                  <div style={{ display: "inline-block" }}>
                    <Button type="primary" onClick={() => {
                      form.handleSubmit();
                    }}>
                      Enregistrer
                    </Button>
                  </div>
                }
              />
            </Fragment>
          )}
        </Form>

      </Layout>
    );
  }
}
