import React, { Component, Fragment } from 'react';
import {
  Layout, Button, Divider, Dropdown, Icon, Menu, Row, Col,
  Checkbox, Tag, Pagination, Select, Upload, Modal, message, Popconfirm
} from 'antd';
import { Link } from 'react-router-dom';
import Filter from './Filter';
import Table from './Table';
import ImportModal from './ImportModal';
import ImportContentsFromKobaSitesModal from './ImportContentsFromKobaSitesModal';
import moment from 'moment';
import ContactsService from 'services/contacts';
import { buildWhere } from 'services/filters';
import Users from 'services/users';

const service = new ContactsService();
const dateFormat = 'DD/MM/YYYY';
const pageSize = 20;
const apiBaseURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
const usersService = new Users();

export default class ContactsList extends Component {
  state = {
    contacts: [],
    contactsCount: 0,
    selectedRowKeys: [],
    pageNumber: 1,
    filters: null,
    loading: false,
    orderBy: 'updatedAt',
    orderDirection: 'desc',
    userSites: null
  }

  componentDidMount() {
    this.fetchContacts();
    this.fetchUserSites();
  }

  fetchContacts() {
    const { pageNumber, filters, orderBy, orderDirection } = this.state;
    const limit = pageSize;
    const offset = pageSize * (pageNumber - 1);
    const where = buildWhere(filters);
    const order = orderDirection === 'desc' ? `-${orderBy}` : orderBy
    const params = { where };
    this.setState({ loading: true });
    service.get({ params, limit, offset, order }).then(resp => {
      this.setState({
        contacts: resp.rows,
        contactsCount: resp.count,
        loading: false
      });
    });
  }

  fetchUserSites() {
    usersService.sites().then(userSites => {
      this.setState({
        userSites
      });
    })
  }

  // Handlers

  handleContactDelete = ({ id }) => {
    service.delete(id).then(() => {
      this.fetchContacts();
    })
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      pageNumber
    }, () => {
      this.fetchContacts();
    })
  }

  handleFiltersSearch = (filters) => {
    this.setState({
      pageNumber: 1,
      filters
    }, () => {
      this.fetchContacts()
    });
  }

  handleFiltersReset = () => {
    this.setState({
      pageNumber: 1,
      filters: null
    }, () => {
      this.fetchContacts()
    });
  }

  handleOrderByChange = orderBy => {
    this.setState({
      pageNumber: 1,
      orderBy
    }, () => {
      this.fetchContacts();
    });
  }

  handleOrderDirectionChange = orderDirection => {
    this.setState({
      pageNumber: 1,
      orderDirection
    }, () => {
      this.fetchContacts();
    });
  }

  handleImportContactsFromSite = (totalContacts, siteSelected) => {
    this.toggleState('importContactsFromKobaSitesVisible');
    if (totalContacts) {
      message.success(`${totalContacts} contacts ont été importés du site "${siteSelected}"`)
    } else {
      message.error("Aucun contact n'a été importé. Veuillez vérifier les valeurs insérées dans le fichier importé.")
    }
    this.fetchContacts();
  }

  handleBulkDelete = (selectedRowKeys) => {
    this.setState({ loading: true });

    service.bulkDelete(selectedRowKeys)
    .then(({ count }) => {
      this.setState({
        loading: false,
        selectedRowKeys: []
      }, () => {
        message.success(`
          ${ (count == 1)
            ? "1 contact a été supprimé"
            : `${count} contacts ont été supprimés`
          }
        `);
        this.fetchContacts();
      });
    })
    .catch((_err) => {
      this.setState({
        loading: false
      }, () => {
        message.error('Une erreur est survenue lors de la suppression des contacts.');
      });
    })
  }

  // State mutations

  toggleState = field => {
    this.setState( prevState => ({ [field]: !prevState[field] }))
  }

  showImportContactsFromKobaSites = () => {
    const { userSites } = this.state;
    const field = (userSites && userSites.length > 0)
      ? 'importContactsFromKobaSitesVisible'
      : 'youShouldBuyASiteModalVisible';

    this.setState({ [field]: true });
  }

  onSelectChange = selectedRowKeys => this.setState({ selectedRowKeys });

  render() {
    const { contacts, pageNumber, contactsCount,
            loading, orderBy, orderDirection, selectedRowKeys,
            userSites, importContactsFromKobaSitesVisible,
            youShouldBuyASiteModalVisible, importModalVisible
          } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <h1 style={{flex: 1, justifySelf: 'flex-start'}}>Contacts</h1>
            <Link to="/audience/contacts/new">
              <Button type="primary" className="mr-1">Nouveau contact</Button>
            </Link>
            <Dropdown overlay={
              <Menu onClick={() => {}}>
                <Menu.Item key="1" onClick={() => this.setState({ importModalVisible: true })}>Depuis un fichier CSV</Menu.Item>
                <Menu.Item key="2" onClick={() => window.open(`${apiBaseURL}/files/contacts-import-template.csv`)}>Télécharger un modèle de fichier d'import CSV</Menu.Item>
                <Menu.Item key="3" onClick={ this.showImportContactsFromKobaSites}>Depuis mon site Koba Civique</Menu.Item>
              </Menu>
            }>
              <Button style={{ marginLeft: 8 }}>
                Importer <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </div>
        <Filter
          className="mt-4"
          onFiltersSearch={this.handleFiltersSearch}
          onFiltersReset={this.handleFiltersReset}
        />
        <div style={{ padding: '10px' }}>
          <Popconfirm title="Etes-vous sûr de vouloir supprimer ?"
            onConfirm={() => {this.handleBulkDelete(selectedRowKeys)}}
            onCancel={() => {}} okText="oui" cancelText="non"
          >
            <Button type="danger"
                    disabled={!hasSelected}
                    loading={loading}
              style={{marginRight: '10px'}}
            >
              {
                (selectedRowKeys.length == 1)
                ? "Supprimer le contact séléctionné"
                : `Supprimer les ${(hasSelected) ? `${selectedRowKeys.length} ` : ''}contacts séléctionnés`
              }
            </Button>
          </Popconfirm>

          <span className="float-right mb-2">
            Trier par
           <Select value={orderBy} onChange={this.handleOrderByChange} size="large" style={{ width: '200px', marginLeft: '20px' }} className="ml-2 mr-2">
              <Select.Option value="lastname">Nom</Select.Option>
              <Select.Option value="createdAt">date de création</Select.Option>
              <Select.Option value="updatedAt">date de mise à jour</Select.Option>
              <Select.Option value="optinEmailAt">date d'abonnement email</Select.Option>
              <Select.Option value="optinSmsAt">date d'abonnement SMS</Select.Option>
            </Select>
            <Select value={orderDirection} onChange={this.handleOrderDirectionChange} size="large" style={{ width: '150px' }} className="ml-2">
              <Select.Option value="desc">{orderBy === 'lastname' ? 'décroissant' : 'décroissante'}</Select.Option>
              <Select.Option value="asc">{orderBy === 'lastname' ? 'croissant' : 'croissante'}</Select.Option>
            </Select>
          </span>
        </div>
        <Table
          contacts={contacts}
          loading={loading}
          rowSelection={rowSelection}
          onContactDelete={this.handleContactDelete}
        />
        <div style={{ padding: '10px' }}>
          <Pagination
            className="float-right"
            current={pageNumber}
            onChange={this.handlePageChange}
            pageSize={pageSize}
            total={contactsCount}
            showTotal={total => `${total} contacts trouvés au total`} />
        </div>

        <ImportModal visible={importModalVisible} onClose={() => this.setState({ importModalVisible: false }, () => this.fetchContacts())} />


        <ImportContentsFromKobaSitesModal
          title="Importer les contacts d'un site Koba Civique"
          contentKind="contacts"
          visible={importContactsFromKobaSitesVisible}
          userSites={userSites}
          taskToPerform={service.importFromKobaSites}
          onCancel={() => this.toggleState('importContactsFromKobaSitesVisible')}
          onOk={this.handleImportContactsFromSite}
        />

        <Modal
          title="Vous n'avez pas encore de site Koba Civique"
          width={700}
          visible={youShouldBuyASiteModalVisible}
          onCancel={() => this.toggleState('youShouldBuyASiteModalVisible') }
          footer={
            <Button onClick={() => this.toggleState('youShouldBuyASiteModalVisible')}>Annuler</Button>
          }
        >
          <a href="https://www.koba-civique.com/" target="_blank"><Button>En savoir plus sur les sites Koba Civique</Button></a>
        </Modal>
      </Layout>
    );
  }

}
