import { guid } from './utils.js';
import ApiStore from './apiStore';
import axios from 'axios';
import update from 'immutability-helper';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000";

export const buildEmailMjml = (sectionsMjml) => {
  return {
    "tagName": "mjml",
    "children": [{
      "tagName": "mj-body",
      "attributes": {
        "background-color": "#fff"
      },
      "children": sectionsMjml
    }]
  };
}

export const mjmlToHtml = (mjml) => {
  return axios.post(`${baseURL}/mjml/compile`, mjml);
}

class EmailsTemplates extends ApiStore {

  constructor() {
    super('/templates');
  }

}

export default EmailsTemplates;
