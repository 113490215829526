import React, { Fragment } from 'react'
import Section from '..'
import Form from 'components/Form'

const { Input, ColorPicker, RichText } = Form;

export const heroTextAsMjml = ({ backgroundColor, title, content: { html } = {} }) => ({
  "tagName": "mj-section",
  "attributes": {
    "background-color": backgroundColor,
    "full-width": "full-width"
  },
  "children": [{
    "tagName": "mj-column",
    "attributes": {
      "width": "100%",
      "vertical-align": "middle"
    },
    "children": [{
      "tagName": "mj-text",
      "attributes": {
        "font-family": "Helvetica Neue",
        "align": "center",
        "font-size": "18px",
        "color": "#fff",
        "padding-top": "25px",
        "padding-bottom": "0"
      },
      "content": title || ''
    }, {
      "tagName": "mj-divider",
      "attributes": {
        "border-color": "#fff",
        "border-style": "solid",
        "border-width": "1px",
        "padding-left": "100px",
        "padding-right": "100px",
        "padding-bottom": "20px",
        "padding-top": "20px"
      }
    }, {
      "tagName": "mj-text",
      "attributes": {
        "font-family": "Helvetica Neue",
        "align": "center",
        "font-size": "12px",
        "padding-top": "0",
        "padding-bottom": "25px",
        "color": "#fff"
      },
      "content": html || ''
    }]
  }]
});

export default class HeroText extends Section {
  constructor(props) {
    super(props, "Mise en avant")
  }

  asMjml = () => heroTextAsMjml(this.props.attributes);

  renderConfigForm = (form) => {
    return (
      <Fragment>
        <ColorPicker
          label="Couleur de fond"
          path={['backgroundColor']}
          form={form} />
        <Input
          label="Titre"
          path={['title']}
          form={form} />
        <RichText
          label="Texte"
          path={['content']}
          form={form} />
      </Fragment>
    )
  }
}
