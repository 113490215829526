import React, { Component } from 'react';
import { Button, Table, Popconfirm, Input, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./index.css";

class ApiTable extends Component {
  static propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    service: PropTypes.object,
    basePath: PropTypes.string,
    searchKeys: PropTypes.array,
    deleteSuccessText: PropTypes.string,
    getParams: PropTypes.func,
    readOnly: PropTypes.bool,
    disableSearch: PropTypes.bool
  }

  state = {
    initialData: [],
    data: []
  }

  componentWillMount() {
    this.load();
  }

  load = () => {
    const { getParams } = this.props;
    this.setState({loading: true});
    this.props.service.get(getParams && getParams()).then(data => {
      this.setState({
        initialData: data.rows,
        data: data.rows,
        loading: false
      })
    });
  }

  search = (text) => {
    const { searchKeys } = this.props;
    this.setState(state => ({
      data: state.initialData.filter(doc => {
        for (let key of searchKeys) {
          if ((doc[key] || '').toLowerCase().includes(text.toLowerCase())) return true
        }
        return false;
      })
    }));
  }

  duplicateItem = (doc) => {
    const { service, history, basePath } = this.props;
    service.duplicate(doc).then(duplicateCampaign => {
      history.push(basePath + '/edit/' + duplicateCampaign.id + '?step=0');
    });
  }

  deleteItem = (id) => {
    const { deleteSuccessText, service } = this.props;
    service.delete(id).then(_ => {
      this.setState(state => {
        const data = state.data.filter(c => c.id !== id);
        return {
        initialData: data,
        data
        };
      });
      message.success(deleteSuccessText || "la ligne a bien été supprimée");
    });
  }


  render() {
    const { data, loading } = this.state;
    const { columns, basePath, readOnly } = this.props;
    const tableColumns = readOnly ? columns : [ ...columns, {
        title: '',
        key: 'action',
        render: (_, doc) => (
          <span>
            { basePath.includes('/campaigns') && <Button icon="copy" onClick={() => this.duplicateItem(doc)} style={{ marginRight: '5px' }}/>}
            <Link to={{ pathname: `${basePath}/edit/${doc.id}`, search: window.location.search }}><Button icon="edit" style={{ marginRight: '5px' }} /></Link>
            <Popconfirm title="Etes-vous sûr de vouloir supprimer ?"
              onConfirm={() => this.deleteItem(doc.id)}
              onCancel={() => {}} okText="oui" cancelText="non"
            >
              <Button icon="delete" />
            </Popconfirm>
          </span>
        ),
        style: { textAlign: 'right' },
        width: 130
      }
    ];
    return (
      <>
        {!this.props.disableSearch && (
          <div style={{ width: "100%", padding: 10, height: 40 }}>
            <Input.Search
              size="large"
              placeholder="Filtrer..."
              onSearch={this.search}
              style={{ width: "50%" }}
            />
          </div>
        )}
        <Table
          size="middle"
          dataSource={data}
          rowKey="id"
          columns={tableColumns}
          loading={loading}
          locale={{emptyText: "Pas de données pour le moment..."}}/>
      </>
    );
  }
}

export default withRouter(ApiTable);
