import React, { Component } from 'react';
import { Modal, Row, Col, Button, Statistic, Spin } from 'antd';
import CampaignsSmsService from '../../../../services/campaignsSms';
import './index.css';

const service = new CampaignsSmsService();

export default class StatisticsModal extends Component {
  state = {
    statistics: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({statistics: null});
      service.getCampaignStatistics(this.props.campaignId).then(res => {
        this.setState({statistics: res.data});
      });
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const { statistics } = this.state;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={
          <Button type="primary" onClick={onCancel}>OK</Button>
        }
      >
        {!statistics && (
          <div className="StatisticsModal-centered">
            <Spin />
          </div>
        )}
        {statistics && (
          <Row>
          <Col span={12}><Statistic title="Envoyés" value={statistics.cost} /></Col>
            <Col span={12}><Statistic title="Délivrés" value={statistics.delivered} /></Col>
            <Col span={12}><Statistic title="Erreurs" value={statistics.errors} /></Col>
            <Col span={12}><Statistic title="Expirés" value={statistics.expired} /></Col>
            <Col span={12}><Statistic title="Blockés" value={statistics.stop} /></Col>
            <Col span={12}><Statistic title="Numéro non attribué" value={statistics.npai} /></Col>
            <Col span={12}><Statistic title="Erreur réseau" value={statistics.networkError} /></Col>
          </Row>
        )}
      </Modal>
    );
  }
}
