import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000"
const http = axios.create({
  baseURL
})
http.interceptors.response.use((response) => response.data)

export const uploadFile = (file, name) => {
  const formData = new FormData()
  formData.append('file', file, name)
  return http.post("/upload", formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  })
}
