import React, { Fragment } from 'react';
import Section from '..';
import { Button } from 'antd';
import Form from 'components/Form';

const { FileUpload } = Form;

export const headerImageAsMjml = ({ backgroundUrl }) => ({
  "tagName": "mj-section",
  "attributes": {
    "background-url": backgroundUrl || "https://www.commune-nointel.fr/sites/5a9965885ca4830014e00e2b/theme/images/footer-nointel.png?7bf58c30a30bcc69d80cf2b2c9357d31",
    "background-size": "cover",
    "full-width": "full-width",
    "background-repeat": "no-repeat",
    "background-color": "#d7dde5",
    "padding-top": "150px"
  }
});

export default class HeaderImage extends Section {
  constructor(props) {
    super(props, "Header image");
  }

  asMjml = () => headerImageAsMjml(this.props.attributes)

  renderConfigForm = (form, meta = {}) => {
    return (
      <Fragment>
        <FileUpload
          maxHeight={150}
          ratio={meta.imageRatio}
          path={['backgroundUrl']}
          label="Logo"
          form={form} />
      </Fragment>
    )
  }
}
