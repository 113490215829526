import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button, Card, Icon, Col, Row, Tag } from 'antd';
import CampaignsEmailService from '../../services/campaignsEmail';
import CampaignsSmsService from '../../services/campaignsSms';
import moment from 'moment';
import 'moment/locale/fr';
import './index.css';

moment.locale('fr');

// partition([1, 2, 3, 4], 2) => [[1, 2], [3, 4]]
const partition = ([h, ...t], n, [accH, ...accT] = []) =>
  !h ? (!accH ? [] : [accH, ...accT].map(els => els.reverse()).reverse()) :
    partition(
      t, n,
      (!accH || accH.length < n) ? [[h, ...(accH || [])], ...accT] : [[h], ...[accH, ...accT]]
    );

export default class Dashboard extends Component {
  state = {
    campaigns: null
  }
  campaignsEmailService = new CampaignsEmailService();
  campaignsSmsService = new CampaignsSmsService();

  componentWillMount() {
    const comparator = (a, b) => moment(a.updatedAt || a.createdAt).isBefore(moment(b.updatedAt || b.createdAt)) ? 1 : -1;
    this.campaignsEmailService.get().then(data => {
      this.setState(state => ({
        campaigns: (state.campaigns || []).concat(data.rows.map(c => ({ ...c, kind: "email" }))).sort(comparator)
      }));
    });
    this.campaignsSmsService.get().then(data => {
      this.setState(state => ({
        campaigns: (state.campaigns || []).concat(data.rows.map(c => ({ ...c, kind: "sms" }))).sort(comparator)
      }));
    })
  }

  render() {
    const { client } = this.props;
    if (!client) return null;
    const { campaigns } = this.state;
    return (
      <Layout>

        {/*<div className="Content-header">
          <h1>Ma consommation</h1>
        </div>*/}
        <Row gutter={64} style={{paddingLeft: 64, paddingRight: 64}}>
          <Col span={12} style={{textAlign: 'center', marginBottom: '25px'}}>
            <h2>Crédits Email</h2>
            <Card style={{backgroundColor: '#e6f7ff', color: 'black'}} className="Dashboard-card">
              <div>
                <div style={{fontSize: '2rem'}}>
                  <i className="fa fa-envelope" style={{marginRight: '0.5rem'}}/>
                  <strong>{client.emailCredits || 0}</strong>
                </div>
              </div>
            </Card>
            <Link to="/campaigns/email/new" className="mr-1">
              <Button type="primary" className="mt-2">Envoyer une campagne email</Button>
            </Link>
          </Col>
          <Col span={12} style={{textAlign: 'center', marginBottom: '25px'}}>
            <h2>Crédits SMS</h2>
            <Card style={{backgroundColor: '#e6f7ff', color: 'black'}} className="Dashboard-card">
              <div>
                <div style={{fontSize: '2rem'}}>
                  <i className="fa fa-comment-alt" style={{marginRight: '0.5rem'}}/>
                  <strong>{client.smsCredits || 0}</strong>
                </div>
              </div>
            </Card>
            <Link to="/campaigns/sms/new">
              <Button type="primary" className="mt-2">Envoyer une alerte SMS</Button>
            </Link>
          </Col>
          <Col span={24} style={{textAlign: 'center'}}>
            <Button
              icon="info-circle" className="mt-4 mb-4"
              onClick={() => window.location.href='mailto:succes.client@koba.com'}
              style={{backgroundColor: 'lightgreen'}}>Contactez-nous pour recharger vos crédits</Button>
          </Col>
        </Row>
        {/*<div style={{ fontSize: '1.2rem' }}>
          <strong>0</strong> emails et <strong>0</strong> SMS consommés
        </div>*/}
        <div className="Content-header mt-1">
          <h1>Mes dernières campagnes</h1>
        </div>
        <div style={{marginBottom: "2rem"}}>
          {partition(campaigns || [], 2).map((campaigns, i) => (
            <Row key={i} gutter={16} style={{ marginTop: '1rem' }}>
              {campaigns && campaigns.map(({id, name, kind, createdAt, updatedAt, sendAt, sentAt, statistics, sentContactsCount}) =>
                <Col key={id} span={12}>
                  <Link to={`/campaigns/${kind}/edit/${id}`} style={{color: "black"}}>
                    <Card
                    className="Dashboard-card"
                    cover={<div style={{ width: '100%', textAlign: 'center', paddingTop: 30 }}><i className={"fa fa-" + (kind === 'email' ? 'envelope' : 'comment-alt')} style={{ color: '#4087c2', fontSize: '5rem' }}></i></div>}
                  >
                    <Card.Meta
                      title={
                        <Fragment>
                          <span>{name}</span>
                          {' '}
                          {sentAt && (<Tag color="lightgreen" style={{color: 'black'}}>envoyée {moment(sentAt).fromNow()}</Tag>)}
                          {!sentAt && sendAt && (<Tag color="#fadb14" style={{color: 'black'}}>planifiée pour l'envoi</Tag>)}
                          {!sentAt && !sendAt && (<Tag color="lightblue" style={{color: 'black'}}>éditée {moment(updatedAt || createdAt).fromNow()}</Tag>)}
                        </Fragment>
                      }
                      description={
                        <Fragment>
                          {kind === 'sms' && statistics && statistics.deliveryRate && (
                            <Fragment>
                              <Icon type="area-chart" />{'  '}
                              {statistics.deliveryRate || '-'}% distribués
                              sur {statistics.cost} envoyés {'  '}
                            </Fragment>
                          )}
                          {kind === 'email' && sentContactsCount != null && (
                            <Fragment>
                              <Icon type="area-chart" />{'  '}
                              {sentContactsCount || '-'} email{sentContactsCount > 1 && 's'} envoyé{sentContactsCount > 1 && 's'}
                            </Fragment>
                          )}
                        </Fragment>
                      }
                    />
                  </Card>
                  </Link>
                </Col>
              )}
            </Row>
          ))}
          {(campaigns || []).length === 0 && (
            <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col span={24}>
              <Card
                className="Dashboard-card"
                cover={<div style={{ width: '100%', textAlign: 'center', paddingTop: 30 }}><i className="fa fa-bullhorn" style={{ color: '#4087c2', fontSize: '5rem' }}></i></div>}
              >
                <Card.Meta
                  title={
                    <Fragment>
                      <span>Créez votre première campagne</span><br /><br />
                      <Link to="/campaigns/email/new">
                        <Button type="primary">Email</Button>
                      </Link>
                      {' '}
                      ou
                      {' '}
                      <Link to="/campaigns/sms/new">
                        <Button type="primary" size="medium">SMS</Button>
                      </Link>
                      <br /><br />
                    </Fragment>
                  }
                  description="Vous pouvez la tester sans crédits"
                  style={{textAlign: 'center'}}
                />
              </Card>
            </Col>
            </Row>
          )}
        </div>

        {/*
        <Layout.Content className="App-content">
          <Card
            style={{ width: 300 }}
            cover={<div style={{ width: '100%', textAlign: 'center' }}><i class="fa fa-envelope" style={{ fontSize: '8rem' }}></i></div>}
            actions={[<Icon type="eye" />, <Icon type="edit" />, <Icon type="ellipsis" />]}
          >
            <Card.Meta
              title="Card title"
              description="This is the description"
            />
          </Card>


        </Layout.Content>
        */}
      </Layout>
    );
  }

}
