import React from 'react'
import { Alert } from 'antd';

export default class extends React.Component {
  static getDerivedStateFromError() {
    return {error: true}
  }

  state = {error: null}

  render() {
    const {error} = this.state
    const {children, ...props} = this.props
    return (
      <div {...props}>
        {error
          ? <Alert
              message="Erreur"
              description="Une erreur est survenue veuillez contacter le service client"
              type="error"
              showIcon
            />
          : children}
      </div>
    )
  }
}
