import React from 'react';
import PublicApp from 'providers/PublicApp'
import { Helmet } from "react-helmet";
import Routes from './Routes'
import ErrorCatcher from './ErrorBoundary'

export default () => (
  <ErrorCatcher>
    <PublicApp>
      <PublicApp.Consumer>
      {({data: {name}}) =>
        <>
          <Helmet>
            <title> {name ? `Mairie de ${name} |` : ''} Inscription Pack Communication</title>
            <meta name="description" content="Description a definir" />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,600,700&display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Rubik&display=swap" rel="stylesheet" />
          </Helmet>
          <Routes />
        </>
      }
      </PublicApp.Consumer>
    </PublicApp>
  </ErrorCatcher>
)
