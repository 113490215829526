import React, { Component, Fragment } from 'react';
import { Modal, Button, Icon, Select, Form, Input, Upload, Popconfirm, message, Card } from 'antd';
import BottomBar from '../../../../../components/BottomBar';
import AudienceForm from '../../../AudienceForm';

const FormItem = Form.Item;
const Option = Select.Option;

export default class CampaignEditorAudience extends Component {
  state = {
    selectedContacts: [],
    phones: [],
    campaign: this.props.data
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    const { data } = this.props;
    this.setState({
      selectedContacts: [],
      phones: data && data.phones || [],
      isFormTouched: false
    });
  }

  removePhoneField = (i) => {
    this.setState((state, props) => ({
      phones: state.phones.filter((_, eIndex) => eIndex !== i),
      isFormTouched: true
    }));
  }

  addPhoneField = () => {
    this.setState((state, props) => ({
      phones: [ ...state.phones, "" ],
      isFormTouched: true
    }));
  }

  handlePhoneChange = (e, i) => {
    const nextPhone = e.target.value;
    this.setState((state, props) => ({
      phones: state.phones.map((e, phoneIndex) => (phoneIndex === i) ? nextPhone : e),
      isFormTouched: true
    }));
  }

  handleSubmit = () => {
    const { campaign } = this.state;
    this.props.onCampaignAudienceSubmit(
      campaign
    ).then(this.setState({
      isFormTouched: false
    }));
  }

  handleAudienceChange = (campaign) => {
    this.setState({
      isFormTouched: true,
      campaign
    });
  }

  render() {
    const { data } = this.props;
    const { phones, selectedContacts, isFormTouched, testModalVisible, testPhone, targetsCount, credits } = this.state;

    let content;
    if (data.sentAt) {
      content = (
        <Card>
          Cette campagne a ciblé un total de {data.sentContactsCount} contact{data.sentContactsCount > 1 && 's'}
        </Card>
      );
    } else {
      content = (
        <Fragment>
          <AudienceForm
            value={data}
            onChange={this.handleAudienceChange}
            onFetchedTargetsCountAndCredits={(targetsCount, credits) => { this.setState({ targetsCount, credits }) }}
            campaignKind="sms" />

          <Modal visible={testModalVisible}
            cancelText='Annuler'
            onOk={() => {
              this.setState({
                testModalVisible: false
              });
              this.props.onTestCampaign([testPhone]);
            }}
            onCancel={() => {
              this.setState({
                testModalVisible: false
              });
            }}>
            <div className="ant-form-item">
              <div className="ant-form-item-label">
                <label>Numéro sur lequel envoyer le test</label>
              </div>
              <div className="ant-form-item-control">
                <span className="ant-form-item-children">
                  <Input placeholder="0606060606" value={testPhone} onChange={e => {
                    this.setState({
                      testPhone: e.target.value
                    });
                  }} />
                </span>
              </div>
            </div>
          </Modal>
        </Fragment>
      );
    }

    return (
      <div>
        {content}
        <BottomBar
          left={
            <Button type="primary" icon="left" onClick={() => this.props.onPrevious()}>configuration</Button>
          }
          right={!data.sentAt && (
            <div style={{ display: "inline-block" }}>
              {isFormTouched && (
                <Button type="primary"
                  onClick={this.handleSubmit}
                >
                  enregistrer
                </Button>
              )}
              {!isFormTouched && (
                <Button onClick={() => {
                  this.setState({
                    testModalVisible: true
                  });
                }}>
                  tester
                </Button>
              )}
              {!isFormTouched && (targetsCount == null || targetsCount > credits) && (
                <Button icon="sound" type="primary" size="large" disabled>
                  Envoyer
                </Button>
              )}
              {(!isFormTouched && (targetsCount != null && targetsCount <= credits)) && (
                <Popconfirm title="La campagne va être envoyée aux contacts choisis, êtes-vous sûr ?"
                  onConfirm={this.props.onSendCampaign}
                  onCancel={() => null} okText="Oui" cancelText="Non"
                >
                  {!isFormTouched && (
                    <Button icon="sound" type="primary" size="large">
                      Envoyer
                    </Button>
                  )}
                </Popconfirm>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
