import React, { Component, Fragment } from 'react'
import { Map, List, Set, getIn, setIn, is } from 'immutable'
import { validate, asImmutable } from '../../services/validation'
import PropTypes from 'prop-types'
import Input from './Input'
import InputNumber from './InputNumber'
import Select from './Select'
import Radio from './Radio'
import Checkbox from './Checkbox'
import ColorPicker from './ColorPicker'
import FileUpload from './FileUpload'
import TextArea from './TextArea'
import RichText from './RichText'
import Date from './Date'

/**
 * The Form component is a wrapper that helps you mutate your data trees with the following "state of the art" features:
 * - Mutate your data for you (use form.handleChange(path, value))
 * - Handle validations "the right way" (split your validations into `rules` and `submitRules`)
 *   and get back the error with form.getError(path)  (use services/Validation under the hood)
 * - Works on an internal copy of your data (allow to `form.reset()` your modifications")
 *
 * The "view" part is entirely up to you, Form handles the data, nothing more.
 * It injects a "form" object in your render structure which contains the following functions:
 * ```js
 * {
 *   handleTouched,
 *   getError,
 *   getValue,
 *   handleChange,
 *   handleSubmit
 * }
 * ```
 */
export default class Form extends Component {
  static propTypes = {
    value: PropTypes.any,
    onSubmitSuccess: PropTypes.func.isRequired,
    onSubmitFailure: PropTypes.func,
    rules: PropTypes.array,
    submitRules: PropTypes.array
  };

  static TextArea = TextArea;
  static Input = Input;
  static InputNumber = InputNumber;
  static FileUpload = FileUpload;
  static Select = Select;
  static Radio = Radio;
  static Checkbox = Checkbox;
  static ColorPicker = ColorPicker;
  static RichText = RichText;
  static Date = Date;

  state = {
    value: this.props.value || {},
    errors: Map(),
    isFormTouched: false,
    fieldsTouched: Set()
  }

  reset = () => {
    this.setState({
      value: this.props.value || {},
      errors: Map(),
      isFormTouched: false,
      fieldsTouched: Set()
    })
  }

  validate = (forSave, onSuccess, onFailure) => {
    let { rules, submitRules } = this.props
    rules = rules || []
    submitRules = submitRules || []
    const { value, fieldsTouched } = this.state
    let errors = asImmutable(validate(value, rules))
    if (forSave) {
      errors = errors.merge(asImmutable(validate(value, submitRules)))
    }
    this.setState(state => ({
      errors,
      forSave: state.forSave || forSave
    }),
    () => {
      if (errors.isEmpty() && onSuccess) onSuccess(value)
      if (!errors.isEmpty() && onFailure) onFailure(errors)
    })
  }

  /**
   * notify the form when a field has been touched
   * validation is re-run each time a field is touched
   * you can attach it to a onChange or onBlur event on your fields
   *
   * @param {array} path
   * @public
   */
  handleTouched = path => {
    this.setState(
      state => ({
        fieldsTouched: state.fieldsTouched.add(List(path))
      }),
      () => this.validate()
    );
  };

  /**
   * get error at path
   *
   * @param {array} path
   * @public
   */
  getError = path => {
    const { fieldsTouched, forSave, errors } = this.state
    const lPath = List(path)
    return (forSave || fieldsTouched.has(lPath)) && errors.get(lPath)
  }

  /**
   * get value at path
   *
   * @param {array} path
   * @public
   */
  getValue = path => getIn(this.state.value, path)

  /**
   * change value at path
   *
   * @param {array} path
   * @param {any} value
   * @param {any} shouldValidate (optional)
   * @public
   */
  handleChange = (path, value, shouldValidate) => {
    this.setState(
      state => ({
        ...setIn(state, ['value', ...path], value),
        fieldsTouched: state.fieldsTouched.add(List(path)),
        errors: state.errors.delete(List(path)),
        isFormTouched: true
      }),
      () => {
        if (shouldValidate) {
          this.validate()
        }
      }
    )
  }

  /**
   * triggers form submission, will run validations before and call back the form prop
   * onSubmitSuccess or onSubmitFailure
   *
   * @public
   */
  handleSubmit = () => {
    this.validate(true, this.props.onSubmitSuccess, this.props.onSubmitFailure)
  };

  /**
   * triggers form submission, will run validations before and call back the form prop
   * onSubmitSuccess or onSubmitFailure
   *
   * @public
   */
   isFormTouched = () => {
     return this.state.isFormTouched
   }

  render() {
    const { children } = this.props
    const { isFormTouched } = this.state

    return (
      <Fragment>
        {/*
          <Prompt when={isFormTouched} message={_ => "Vous perdrez les modifications en cours êtes-vous sûr de vouloir continuer ?"} />
        */}
        {children({
          getValue: this.getValue,
          getError: this.getError,
          handleChange: this.handleChange,
          handleTouched: this.handleTouched,
          handleSubmit: this.handleSubmit,
          isFormTouched: this.isFormTouched,
          reset: this.reset
        })}
      </Fragment>
    )
  }
}
