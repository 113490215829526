import React, { Fragment } from 'react';
import Section from '..';
import { Button, Card, Icon, Modal } from 'antd';
import Form from 'components/Form';

const { Input, FileUpload } = Form;

export const titleWithLogoAsMjml = ({ logoUrl, title, subtitle }) => {
  return {
    "tagName": "mj-section",
    "attributes": {
      "full-width": "full-width",
      "background-color": "#ffffff",
      "padding-top": "30px",
      "padding-bottom": "30px"
    },
    "children": [{
      "tagName": "mj-column",
      "attributes": {
        "width": "50%",
        "vertical-align": "middle"
      },
      "children": [{
        "tagName": "mj-image",
        "attributes": {
          "src": logoUrl || "https://frontoffice-sandbox.koba-civique.fr/static/media/logo-mairie.1b2d0752.png",
          "alt": "logo",
          width: "100px",
          "padding-bottom": "10px",
          "padding-top": "10px",
          "align": "left",
        }
      }]
    }, {
      "tagName": "mj-column",
      "attributes": {
        "width": "50%",
        "vertical-align": "middle"
      },
      "children": [{
        "tagName": "mj-text",
        "attributes": {
          "font-family": "Helvetica Neue",
          "font-weight": "bold",
          "color": "#45474e",
          "padding-bottom": "0px",
          "padding-top": "10px",
          "align": "left",
          "font-size": "30px",
          "line-height": "30px",
        },
        "content": title || ''
      }, {
        "tagName": "mj-text",
        "attributes": {
          "font-family": "Helvetica Neue",
          "color": "#45474e",
          "padding-bottom": "0px",
          "padding-top": "10px",
          "align": "left",
          "font-size": "14px"
        },
        "content": subtitle || ''
      }]
    }]
  }
}

export default class TitleWithLogo extends Section {
  constructor(props) {
    super(props, "Titre avec logo");
  }

  asMjml = () => titleWithLogoAsMjml(this.props.attributes)

  renderConfigForm = (form, meta = {}) => {
    return (
      <Fragment>
        <FileUpload
          maxWidth={150}
          maxHeight={80}
          ratio={meta.imageRatio}
          path={['logoUrl']}
          label="Logo"
          form={form} />
        <Input
          path={['title']}
          label="Titre"
          form={form} />
        <Input
          path={['subtitle']}
          label="Sous-titre"
          form={form} />
      </Fragment>
    );
  }
}
