import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types'
import './index.css';

class BottomBar extends Component {
  static propTypes = {
    /** Children displayed at left */
    left: PropTypes.node,
    /** Children displayed at right */
    right: PropTypes.node
  }
  render() {
    const { left, right } = this.props;
    return (
      <Row className="BottomBar">
        <Col span={6}>
          {left}
        </Col>
        <Col span={18} style={{ textAlign: "right" }}>
          {right}
        </Col>
      </Row>
    );
  }
}

export default BottomBar;
