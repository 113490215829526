import { guid, getSlugQuery, getTokenHeader } from './utils.js';
import ApiStore from './apiStore';

export default class Config extends ApiStore {

  constructor() {
    super('config');
  }

  getConfig = () =>
    this.http.get(`${this.basePath}${getSlugQuery()}`, getTokenHeader());

  setConfig = config =>
    this.http.put(`${this.basePath}${getSlugQuery()}`, config, getTokenHeader());

}
