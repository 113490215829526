import React from 'react'
import PublicApp from 'providers/PublicApp'
import headerLogo from 'templates/PublicAppTemplate/assets/img/koba logo.png'

export default ({className}) => {
  return (
    <PublicApp.Consumer>
      {({ data: { logoUrl, landingPageBackgroundImageUrl }}) =>
        (
          <>
            <style>
              {
                landingPageBackgroundImageUrl ? `.landing-container { 
                  background: transparent url('${landingPageBackgroundImageUrl}') center center / cover no-repeat padding-box fixed;
                }` : ''
              }
            </style>
            <header className={className}>
              <img className="header-logo" src={logoUrl || headerLogo} alt="Header logo" />
            </header>
          </>
        )
      }
    </PublicApp.Consumer>
  )
}
