import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio as AntdRadio } from 'antd'

export default class Radio extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    /** Ex: [{value: "male", label: "Homme"}, {value: "female", label: "Femme"}] */
    options: PropTypes.array,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { label, path, form, options, style, className } = this.props
    const value = form.getValue(path)
    const handleChange = e => form.handleChange(path, e.target.value)
    const handleRadioClick = (optionValue) => {
      if (value === optionValue) {
        form.handleChange(path, null);
      }
    }
    const error = form.getError(path)
    const handleBlur = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className="ant-form-item-label">
          <label>{label}</label>
        </div>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <AntdRadio.Group value={value} onChange={handleChange}>
              {options.map(({value, label}) =>
                <AntdRadio key={value} value={value}  onBlur={handleBlur} onClick={() => handleRadioClick(value)}>{label}</AntdRadio>
              )}
            </AntdRadio.Group>
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
        </div>
      </div>
    )
  }
}
