import React, { Component, Fragment } from 'react';
import { getIn, setIn, updateIn } from 'immutable';
import { Row, Col, Button, Input, InputNumber, Select, Radio, Checkbox, DatePicker } from 'antd';
import ConfigService from 'services/config';
import ContactsService from 'services/contacts';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/fr_FR';

const dateFormat = 'DD/MM/YYYY';

const isDate = v => ['birthdate', 'date1', 'date2', 'date3', 'date4', 'date5'].includes(v);
const isNumber = v => ['float1', 'float2', 'float3'].includes(v);
const isText = v => ['email', 'addressPostalCode', 'addressCity', 'text1', 'text2', 'text3', 'text4', 'text5'].includes(v);
const isBoolean = v => ['optinEmail', 'optinSms', 'boolean1', 'boolean2', 'boolean3', 'boolean4', 'boolean5'].includes(v);

const configService = new ConfigService();
const contactsService = new ContactsService();

export default class FiltersBuilder extends Component {
  state = {
    fieldsSource: {},
    contacts: []
  }

  componentDidMount() {
    this.fetchConfig();
    this.fetchContacts();
  }

  fetchConfig = () => {
    configService.getConfig().then(config => {
      this.setState({
        config: config || {}
      });
    });
  }

  fetchContacts = () => {
    const { value: filters } = this.props;

    contactsService.get().then(({ rows: contacts }) => {
      this.setState({
        contacts,
        fieldsSource: this.initFiltersSelectOptions({ filters, contacts })
      })
    });
  }

  initFiltersSelectOptions = ({ filters, contacts }) => {
    return [...new Set(filters.children.map(({ key }) => key))]
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: [...new Set(contacts.map(c => c[key]).filter(c => c))]
        })
        , {}
      )
  }

  addFilter = () => {
    const { value, onChange } = this.props;
    onChange(
      updateIn(value, ['children'], children => children.concat({
        operator: '=',
        key: null,
        param: null
      }))
    );
  }

  deleteFilter = (filterIndex) => {
    const { value, onChange } = this.props;
    onChange(
      updateIn(value, ['children'], children => children.filter((_, i) => (filterIndex !== i)))
    );
  }

  handleOperatorChange = (childrenIndex, val) => {
    const { value, onChange } = this.props;
    onChange(
      setIn(value, ['children', childrenIndex, 'operator'], val)
    );
  }

  handleKeyChange = (childrenIndex, val) => {
    const { onChange } = this.props;
    let { value } = this.props;
    value = setIn(value, ['children', childrenIndex, 'key'], val);
    if (isBoolean(val)) {
      value = setIn(value, ['children', childrenIndex, 'param'], true);
    } else {
      // reset the param value
      value = setIn(value, ['children', childrenIndex, 'param'], null);
    }
    onChange(value);
    this.setState(({ fieldsSource }) =>
      Object.assign({
        ...( !fieldsSource[val] &&  { fieldsSource: {
          ...fieldsSource,
          [val]: [...new Set(this.state.contacts
                            .map( (c) => c[val])
                            .filter(c => c)
                            )
                ]
          }})
      })
    )
  }

  handleParamChange = (childrenIndex, val) => {
    const { value, onChange } = this.props;
    onChange(
      setIn(value, ['children', childrenIndex, 'param'], val)
    );
  }

  render() {
    let { config, fieldsSource } = this.state;
    config = config || {};
    config.contactFieldsMapping = config.contactFieldsMapping || {};
    const getContactFieldLabel = (key, defaultLabel) =>
      config.contactFieldsMapping[key] ? config.contactFieldsMapping[key] + ` (${defaultLabel.toLowerCase()})` : defaultLabel;
    const filters = this.props.value;
    return (
      <Fragment>
        {filters.children.map((filter, i) => (
          <Row gutter={16} className="mb-1" key={i}>
            <Col span={8}>
              <Select
                size="large"
                value={filter.key}
                onChange={v => this.handleKeyChange(i, v)}
                style={{width: '100%'}}
              >
                <Select.Option key="email">Email</Select.Option>
                <Select.Option key="birthdate">Date de naissance</Select.Option>
                <Select.Option key="gender">Civilité</Select.Option>
                <Select.Option key="addressPostalCode">Code postal</Select.Option>
                <Select.Option key="addressCity">Ville</Select.Option>
                <Select.Option key="optinEmail">Abonné email</Select.Option>
                <Select.Option key="optinSms">Abonné SMS</Select.Option>
                <Select.OptGroup label="Champs supplémentaires">
                  <Select.Option key="text1">{getContactFieldLabel("text1", "Champ texte 1")}</Select.Option>
                  <Select.Option key="text2">{getContactFieldLabel("text2", "Champ texte 2")}</Select.Option>
                  <Select.Option key="text3">{getContactFieldLabel("text3", "Champ texte 3")}</Select.Option>
                  <Select.Option key="text4">{getContactFieldLabel("text4", "Champ texte 4")}</Select.Option>
                  <Select.Option key="text5">{getContactFieldLabel("text5", "Champ texte 5")}</Select.Option>
                  <Select.Option key="float1">{getContactFieldLabel("float1", "Champ nombre 1")}</Select.Option>
                  <Select.Option key="float2">{getContactFieldLabel("float2", "Champ nombre 2")}</Select.Option>
                  <Select.Option key="float3">{getContactFieldLabel("float3", "Champ nombre 3")}</Select.Option>
                  <Select.Option key="date1">{getContactFieldLabel("date1", "Champ date 1")}</Select.Option>
                  <Select.Option key="date2">{getContactFieldLabel("date2", "Champ date 2")}</Select.Option>
                  <Select.Option key="date3">{getContactFieldLabel("date3", "Champ date 3")}</Select.Option>
                  <Select.Option key="date4">{getContactFieldLabel("date4", "Champ date 4")}</Select.Option>
                  <Select.Option key="date5">{getContactFieldLabel("date5", "Champ date 5")}</Select.Option>
                  <Select.Option key="boolean1">{getContactFieldLabel("boolean1", "Champ case à cocher 1")}</Select.Option>
                  <Select.Option key="boolean2">{getContactFieldLabel("boolean2", "Champ case à cocher 2")}</Select.Option>
                  <Select.Option key="boolean3">{getContactFieldLabel("boolean3", "Champ case à cocher 3")}</Select.Option>
                  <Select.Option key="boolean4">{getContactFieldLabel("boolean4", "Champ case à cocher 4")}</Select.Option>
                  <Select.Option key="boolean5">{getContactFieldLabel("boolean5", "Champ case à cocher 5")}</Select.Option>
                </Select.OptGroup>
              </Select>
            </Col>
            {(isText(filter.key) || isNumber(filter.key) || isDate(filter.key)) && (
              <Col span={4}>
                <Select
                  size="large"
                  value={filter.operator}
                  onChange={v => this.handleOperatorChange(i, v)}
                  style={{width: '100%'}}
                >
                  {(isText(filter.key) || isNumber(filter.key) || isDate(filter.key)) && <Select.Option key="=">égal à</Select.Option>}
                  {isDate(filter.key) && <Select.Option key="<=" value="<=">antérieur à</Select.Option>}
                  {isNumber(filter.key) && <Select.Option key="<=" value="<=">inférieur à</Select.Option>}
                  {isDate(filter.key) && <Select.Option key=">=" value=">=">postérieur à</Select.Option>}
                  {isNumber(filter.key) && <Select.Option key=">=" value=">=">supérieur à</Select.Option>}
                  {isText(filter.key) && <Select.Option key="contains" value="contains">contient</Select.Option>}
                </Select>
              </Col>
            )}
            {filter.key === 'gender' && (
              <Col span={14}>
                <Radio.Group
                  size="large"
                  value={filter.param || ''}
                  onChange={e => this.handleParamChange(i, e.target.value)}
                  buttonStyle="solid"
                >
                  <Radio.Button value="male">Mr</Radio.Button>
                  <Radio.Button value="female">Mme</Radio.Button>
                </Radio.Group>
              </Col>
            )}
            {isBoolean(filter.key) && (
              <Col span={14}>
                <Checkbox
                  checked={!!filter.param}
                  onChange={e => this.handleParamChange(i, e.target.checked)}
                  style={{ paddingTop: 10 }}
                >
                </Checkbox>
              </Col>
            )}
            {isText(filter.key) && (
              <Col span={10}>
                <Select
                  locale={{emptyText: ""}}
                  style={{width: '100%'}}
                  mode="tags"
                  value={filter.param || []}
                  onChange={v => this.handleParamChange(i, v)}
                >
              {fieldsSource[filter.key] && fieldsSource[filter.key].map(field => (
                    <Select.Option key={field} value={field}>{field}</Select.Option>
                  ))}
                </Select>
              </Col>
            )}
            {isNumber(filter.key) && (
              <Col span={10}>
                <InputNumber
                  value={filter.param || ''}
                  onChange={v => this.handleParamChange(i, v)}
                />
              </Col>
            )}
            {isDate(filter.key) && (
              <Col span={10}>
                <DatePicker
                  locale={locale}
                  format={dateFormat}
                  size="large"
                  style={{width: '100%'}}
                  value={filter.param && moment(filter.param, dateFormat)}
                  onChange={(date, dateStr) => this.handleParamChange(i, dateStr) }
                />
              </Col>
            )}
            {filter.key == null && (
              <Col span={14}>
                <Input disabled />
              </Col>
            )}
            <Col span={2}>
              <Button
                icon="delete"
                onClick={() => this.deleteFilter(i)}
                style={{ border: 'none', background: '#fafafa' }}/>
            </Col>
          </Row>
        ))}
        <Button icon="plus" onClick={this.addFilter}>Ajouter un critère</Button>
      </Fragment>
    );
  }
}
