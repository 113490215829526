import React from 'react';
import Header from 'templates/PublicAppTemplate/components/Header';
import infoLogo from 'templates/PublicAppTemplate/assets/img/info-24px.svg'
import 'templates/PublicAppTemplate/assets/css/index.css'

export default (props) => {
  const { handleChange, handleSubmit, displayTooltip, data, contact, errors, history } = props;
  const { authorization, addressLine1, ageCategory, addressCity, email, firstname, lastname, gender, phone, addressPostalCode } = contact;
  const { landingPageColor } = data;

  // A way to override what's already in the stylesheet. Placed under the <style> tag below.
  const backgroundColor = landingPageColor ? `.landing-container:before { background: ${landingPageColor}; opacity: .8; } 
  form button.btn-submit { background: #444; }
` : '';

  return (
    <>
      <style>
        {backgroundColor}
      </style>
      <div className="landing-container">
        <Header />

        <main>
          <p className="subtitle">Inscrivez-vous pour recevoir les infos de la commune</p>
          <form className="registration" onSubmit={(e) => handleSubmit(e, history)}>
            <div className="form-group">
              <div className="landing-custom-select">
                <select name="gender" id="gender" className="adjusted-padding" defaultValue={gender} onChange={handleChange} required>
                  <option value="" hidden>* Civilité</option>
                  <option value="male">Monsieur</option>
                  <option value="female">Madame</option>
                </select>
              </div>
              <input type="text" placeholder="Nom" name="lastname" className="input-control" defaultValue={lastname} onChange={handleChange} required />
              <input type="text" placeholder="Prenom" name="firstname" className="input-control" defaultValue={firstname} onChange={handleChange} required />
            </div>

            <div className="form-group without-margin">
              <input type="tel" placeholder="** Téléphone portable" name="phone" className={`input-control${(errors.includes('phone') ? ' has-error' : '')}`} defaultValue={phone} onChange={handleChange} pattern="0(6|7)\d{8}" onInvalid={displayTooltip}/>
              <input type="email" placeholder="** Adresse email" name="email" className={`input-control${(errors.includes('phone') ? ' has-error' : '')}`} defaultValue={email} onChange={handleChange} />
            </div>

            <small className="information fit-container">** Remplissez au moins l'un des deux champs ci-dessus pour être
                                                         contacté
                                                         par la
                                                         commune</small>
            <div className="form-group">
              <input type="text" placeholder="Adresse" name="addressLine1" className="input-control" defaultValue={addressLine1} onChange={handleChange} />
            </div>

            <div className="form-group">
              <input type="text" name="addressPostalCode" placeholder="Code postal" maxLength="5" className="input-control" defaultValue={addressPostalCode} onChange={handleChange} />
              <input type="text" name="addressCity" placeholder="Ville" className="input-control" defaultValue={addressCity} onChange={handleChange} />
            </div>

            <div className="form-group">
              <div className="landing-custom-select full-width">
                <select name="ageCategory" defaultValue={ageCategory} onChange={handleChange}>
                  <option value="" hidden>Catégorie d'âge</option>
                  <option value="18-24 ans">18-24 ans</option>
                  <option value="25-39 ans">25-39 ans</option>
                  <option value="40-59 ans">40-59 ans</option>
                  <option value="+ de 60 ans">+ de 60 ans</option>
                </select>
              </div>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" name="authorization" id="authorization" defaultChecked={authorization} onChange={handleChange} />
              <label className="checkbox-text" htmlFor="authorization">* J'autorise la commune à m'envoyer des
                                                                       informations par SMS
                                                                       et/ou par mail.</label>
            </div>

            {errors.includes('authorization') && (
              <div className="tooltip">
                <img src={infoLogo} alt="Information" className="tooltip-info-icon" />
                <span className="tooltiptext">
                  Vous devez cocher la case ci-dessus afin de pouvoir vous inscrire.
                </span>
              </div>
            )}

            <small className="required-tooltip fit-container">* Champs requis</small>
            <button type="submit" className="btn-submit btn-block">S'inscrire</button>
          </form>
        </main>

        <footer>
          <p>
            Les informations collectées par la commune sont strictement confidentielles et ne peuvent être utilisées à
            des
            fins commerciales.
          </p>
        </footer>
      </div>
    </>
  )
}
