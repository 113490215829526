import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPageContainer from '../containers/LandingPageContainer'
import LandingPageConfirmation from '../containers/LandingPageContainer/LandingPageConfirmation'

export default (props) =>
  <Router>
    <Switch>
      <Route exact path="/" component={LandingPageContainer} />
      <Route exact path="/confirmation" component={LandingPageConfirmation} />
    </Switch>
  </Router>
