import React from 'react';
import PrivateAppTemplate from './PrivateApp';
import PublicAppTemplate from 'templates/PublicAppTemplate';

const sandbox = 'campaigns-sandbox.koba-civique.fr'
const production = 'campaigns.koba-civique.com'

export default (props) => {
  switch (window.location.hostname) {
    case sandbox:
    case production:
    case 'localhost':
      return <PrivateAppTemplate {...props} />
    case 'landing': // for development purposes. add `landing` to your /etc/hosts
    default:
      return <PublicAppTemplate {...props}/>
  }
}
