import React, { Component, Fragment } from 'react';
import Form from '../../../../components/Form';
import { Link } from 'react-router-dom';
import { required, isEmail, isPhone, isIn, minLength, maxLength } from 'services/validation';
import BottomBar from 'components/BottomBar';
import { Layout, Button, Icon, Row, Col, Collapse, message } from 'antd';
import { List } from 'immutable';
import ContactsService from 'services/contacts';
import ConfigService from 'services/config'
import moment from 'moment';

const { Panel } = Collapse;
const { Input, InputNumber, Radio, Date, Checkbox } = Form;
const configService = new ConfigService();

export default class ContactEditor extends Component {
  state = {
    contact: null
  }
  contactsService = new ContactsService();

  componentDidMount() {
    const { match } = this.props;
    configService.getConfig().then(config => {
      this.setState({
        config: config || {}
      });
    });
    if (match.params.id) {
      this.contactsService.get(match.params.id).then(contact => {
        if (contact.optinEmailAt) {
          contact.optinEmail = true;
        }
        if (contact.optinSmsAt) {
          contact.optinSms = true;
        }
        this.setState({ contact });
      })
    } else {
      this.setState({ contact: {} })
    }
  }

  handleSubmitSuccess = (values) => {
    const { contact } = this.state;
    values.birthdate = values.birthdate && values.birthdate.utc().format();
    values.date1 = values.date1 && values.date1.utc().format();
    values.date2 = values.date2 && values.date2.utc().format();
    values.date3 = values.date3 && values.date3.utc().format();
    values.date4 = values.date4 && values.date4.utc().format();
    values.date5 = values.date5 && values.date5.utc().format();
    if ((!contact || !contact.optinEmailAt) && values.optinEmail) {
      values.optinEmailAt = moment();
    }
    if ((!contact || !contact.optinSmsAt) && values.optinSms) {
      values.optinSmsAt = moment();
    }
    if (!values.optinEmail) {
      values.optinEmailAt = null;
    }
    if (!values.optinSms) {
      values.optinSmsAt = null;
    }
    const { history, match } = this.props;
    let promise;
    if (match.params.id) {
       promise = this.contactsService.replace(match.params.id, values);
    } else {
      promise = this.contactsService.create(values);
    }
    promise.then(c => {
      message.success("le contact a bien été enregistré");
      history.push('/audience/contacts');
    });
  }

  handleSubmitFailure = (errors) => {
    if (errors.get(List([]))) {
      message.error(errors.get(List([])));
    }
  }

  render() {
    const { contact, config } = this.state;
    const { match } = this.props;
    if (!contact || !config) return null;
    config.contactFieldsMapping = config.contactFieldsMapping || {};
    const getContactFieldLabel = (key, defaultLabel) =>
      config.contactFieldsMapping[key] ? config.contactFieldsMapping[key] + ` (${defaultLabel.toLowerCase()})` : defaultLabel;
    contact.birthdate = contact.birthdate && moment(contact.birthdate);
    contact.date1 = contact.date1 && moment(contact.date1);
    contact.date2 = contact.date2 && moment(contact.date2);
    contact.date3 = contact.date3 && moment(contact.date3);
    contact.date4 = contact.date4 && moment(contact.date4);
    contact.date5 = contact.date5 && moment(contact.date5);
    const { firstname, lastname } = contact;
    const contactName = (firstname || lastname) ? ((firstname || '') + ' ' + (lastname || '')) : '';
    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <Link to="/audience/contacts">
              <Button>voir tous mes contacts</Button>
            </Link>
          </div>
          <h1><Icon className="mr-1" type="edit" />{contactName || 'Nouveau contact'}</h1>
        </div>

        <Form
          className="mt-2"
          value={contact}
          onSubmitSuccess={this.handleSubmitSuccess}
          onSubmitFailure={this.handleSubmitFailure}
          rules={[{
            path: [],
            validators: [contact =>
              ((contact.email != null && contact.email !== '') || (contact.phone != null && contact.phone !== '')) || "merci de saisir au moins un email ou numéro de téléphone"]
          },{
            path: ['firstname'],
            validators: [ maxLength(80) ]
          }, {
            path: ['lastname'],
            validators: [ maxLength(80) ]
          }, {
            path: ['email'],
            validators: [ isEmail ]
          }, {
            path: ['phone'],
            validators: [ isPhone ]
          }, {
            path: ['gender'],
            validators: [ isIn(['male', 'female', 'other']) ]
          }]}
          submitRules={[]}>
          {form => (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Input
                    path={['firstname']}
                    label="Prénom"
                    form={form} />
                </Col>
                <Col span={12}>
                  <Input
                    path={['lastname']}
                    label="Nom"
                    form={form} />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Input
                    path={['email']}
                    label="Email**"
                    form={form} />
                </Col>
                <Col span={12}>
                  <Input
                    path={['phone']}
                    label="Téléphone**"
                    form={form} />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Radio
                    path={['gender']}
                    label="Civilité"
                    options={[
                      {value: "male", label: "M."},
                      {value: "female", label: "Mme"},
                    ]}
                    form={form} />
                </Col>
                <Col span={12}>
                  <Date
                    path={['birthdate']}
                    label="Date de naissance"
                    form={form} />
                </Col>
              </Row>
              <Input
                path={['addressLine1']}
                label="Adresse ligne 1"
                form={form} />
              <Input
                path={['addressLine2']}
                label="Adresse ligne 2"
                form={form} />
              <Row gutter={16}>
                <Col span={12}>
                  <Input
                    path={['addressPostalCode']}
                    label="Code postal"
                    form={form} />
                </Col>
                <Col span={12}>
                  <Input
                    path={['addressCity']}
                    label="Ville"
                    form={form} />
                </Col>
              </Row>
              <Checkbox
                path={['optinEmail']}
                label="Abonné newsletter"
                form={form} />
              <Checkbox
                path={['optinSms']}
                label="Abonné SMS"
                form={form} />
              <Collapse className={this.props.className}>
                <Panel header="Champs supplémentaires" key="1">
                  <Input
                    path={['text1']}
                    label={getContactFieldLabel('text1', 'Champ texte 1')}
                    form={form} />
                  <Input
                    path={['text2']}
                    label={getContactFieldLabel('text2', 'Champ texte 2')}
                    form={form} />
                  <Input
                    path={['text3']}
                    label={getContactFieldLabel('text3', 'Champ texte 3')}
                    form={form} />
                  <Input
                    path={['text4']}
                    label={getContactFieldLabel('text4', 'Champ texte 4')}
                    form={form} />
                  <Input
                    path={['text5']}
                    label={getContactFieldLabel('text5', 'Champ texte 5')}
                    form={form} />
                  <Row gutter={16}>
                    <Col span={12}>
                      <Date
                        path={['date1']}
                        label={getContactFieldLabel('date1', 'Champ date 1')}
                        form={form} />
                    </Col>
                    <Col span={12}>
                      <Date
                        path={['date2']}
                        label={getContactFieldLabel('date2', 'Champ date 2')}
                        form={form} />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Date
                        path={['date3']}
                        label={getContactFieldLabel('date3', 'Champ date 3')}
                        form={form} />
                    </Col>
                    <Col span={12}>
                      <Date
                        path={['date4']}
                        label={getContactFieldLabel('date4', 'Champ date 4')}
                        form={form} />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Date
                        path={['date5']}
                        label={getContactFieldLabel('date5', 'Champ date 5')}
                        form={form} />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <InputNumber
                        path={['float1']}
                        label={getContactFieldLabel('float1', 'Champ nombre 1')}
                        form={form} />
                    </Col>
                    <Col span={8}>
                      <InputNumber
                        path={['float2']}
                        label={getContactFieldLabel('float2', 'Champ nombre 2')}
                        form={form} />
                    </Col>
                    <Col span={8}>
                      <InputNumber
                        path={['float3']}
                        label={getContactFieldLabel('float3', 'Champ nombre 3')}
                        form={form} />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Checkbox
                        path={['boolean1']}
                        label={getContactFieldLabel('boolean1', 'Champ case à cocher 1')}
                        form={form} />
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        path={['boolean2']}
                        label={getContactFieldLabel('boolean2', 'Champ case à cocher 2')}
                        form={form} />
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        path={['boolean3']}
                        label={getContactFieldLabel('boolean3', 'Champ case à cocher 3')}
                      form={form} />
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        path={['boolean4']}
                        label={getContactFieldLabel('boolean4', 'Champ case à cocher 4')}
                      form={form} />
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        path={['boolean5']}
                        label={getContactFieldLabel('boolean5', 'Champ case à cocher 5')}
                      form={form} />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <BottomBar
                right={
                  <div style={{ display: "inline-block" }}>
                    <Button type="primary" onClick={() => {
                      form.handleSubmit();
                    }}>
                      Enregistrer
                    </Button>
                  </div>
                }
              />
            </>
          )}
        </Form>

      </Layout>
    )
  }
}
