import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ApiTable from '../../../../components/ApiTable';
import { Layout, Button, Icon, Modal, Statistic, Row, Col, Tag } from 'antd';
import CampaignsSmsService from '../../../../services/campaignsSms';
import StatisticsModal from '../StatisticsModal';
import moment from 'moment';

const service = new CampaignsSmsService();

export default class CampaignsList extends Component {
  state = {
    statisticsModalVisible: false,
    statisticsModalCampaignId: null,
  }

  showStatisticsModal = (visible, campaignId) => {
    this.setState({
      statisticsModalVisible: visible,
      statisticsModalCampaignId: campaignId
    });
  }

  render() {
    const { statisticsModalVisible, statisticsModalCampaignId } = this.state;
    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <Link to="/campaigns/sms/new">
              <Button type="primary" className="mr-1">Nouvelle campagne SMS</Button>
            </Link>
          </div>
          <h1>Campagnes SMS</h1>
        </div>
        <ApiTable
          service={service}
          basePath="/campaigns/sms"
          deleteSuccessText="La campagne a bien été supprimée"
          searchKeys={['name']}
          columns={[
            {
              title: 'Campagne',
              dataIndex: 'name',
              key: 'id',
              render: (name, row) => (
                <Fragment>
                  <div className="mb-1">
                    {name} {' '}
                    {row.sentAt && (<Tag color="lightgreen" style={{color: 'black'}}>envoyée {moment(row.sentAt).fromNow()}</Tag>)}
                    {!row.sentAt && row.sendAt && (<Tag color="#fadb14" style={{color: 'black'}}>planifiée pour l'envoi</Tag>)}
                    {!row.sentAt && !row.sendAt && (<Tag color="lightblue" style={{color: 'black'}}>éditée {moment(row.updatedAt || row.createdAt).fromNow()}</Tag>)}
                  </div>
                  <span style={{color: '#444'}}>Expéditeur : {row.from}</span>
                </Fragment>
              )
            }, {
              title: "",
              render: (_, row) => (
                <Fragment>
                  {row.sentContactsCount && (
                    <Fragment>
                      <Button icon="area-chart" onClick={() => this.showStatisticsModal(true, row.id)}>voir les statistiques</Button>
                    </Fragment>
                  )}
                </Fragment>
              )
            }
          ]}
        />
        <StatisticsModal
          visible={statisticsModalVisible}
          onCancel={() => this.showStatisticsModal(false)}
          campaignId={statisticsModalCampaignId} />
      </Layout>
    );
  }
}
