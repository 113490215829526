import React, { Component, Fragment } from 'react';
import { Modal, Select, Spin, Button, Alert } from 'antd';
import styled from 'styled-components'
import Users from 'services/users';

const usersService = new Users();
const initialState = {
  siteSelected: null,
  contents: null,
  loading: false,
  errorMessage: null
}

// Wrapper styled component
const Wrapper = styled.div`
  background: rgba(0,0,0,0.05);
  width: 100%;
  text-align: center;
  border-radius: 5px;
  padding: 30px 50px;
`;

export default class ImportContentsFromKobaSitesModal extends Component {
  state = initialState;


  fetch = () => {
    const { siteSelected } = this.state;
    const { contentKind }  = this.props;

    this.setState({
      loading: true,
      errorMessage: null
    });

    usersService.siteContents(siteSelected.slug, contentKind)
    .then(contents => {
      this.setState({
        loading: false,
        ...(contents.length > 0 && { contents }),
        ...(contents.length === 0 && { errorMessage: "Aucun contenu n'a été trouvé." })
      });
    })
    .catch(_ => {
      this.setState({
        loading: false,
        errorMessage: "Une erreur s'est produite lors de la récupération des contenus, veuillez contacter le service client"
      })
    })

  }

  handleOK = () => {
    const { contents,  siteSelected } = this.state;
    const { visible, onOk, taskToPerform } = this.props;
    if (contents) {
      // This case we actually do the import in campaigns-api
      this.setState({
        loading: true,
        errorMessage: null
      });

      taskToPerform(contents)
      .then(({ total }) => {
        // Run callback
        onOk(total, siteSelected.name);

        // Reset State
        this.resetState();
      })
      .catch(_ => {
        // We set an import error message to indicate to the user that something
        // went wrong
        this.setState({
          loading: false,
          contents: null,
          errorMessage: "Une erreur s'est produite lors de l'import. Veuillez contacter le service client."
        })
      })
    } else {
      // This case we fetch the contents from backoffice-api
      this.fetch();
    }
  }
  cancel = () => {
    const { onCancel } = this.props;

    this.resetState();
    onCancel();
  }

  resetState = () => {
    this.setState(initialState);
  }

  render = () => {
    const { visible, title, contentKind, userSites, onCancel } = this.props;
    const { siteSelected, loading, contents, errorMessage } = this.state;
    return (
      <Modal title={title}
        visible={visible}
        onCancel={this.cancel}
        footer={
          <Fragment>
            <Button onClick={this.cancel}>Annuler</Button>
            <Button
              type="primary"
              onClick={this.handleOK}
              disabled={!siteSelected || loading}
            >
              {contents ? "Importer" : "Chercher"}
            </Button>
          </Fragment>
        }
      >
        <div className="ant-form-item">
          <div className="auth-form-item-label">
            <label>Choisir un site :</label>
          </div>
          <Select size="large" style={{width: "100%"}} value={siteSelected ? siteSelected.slug : null}
            onChange={v => {
              this.setState({
                siteSelected: userSites.find(site => site.slug === v)
              });
            }}>
            {userSites && userSites.map(site => (
              <Select.Option key={site.slug} value={site.slug}>{site.name}</Select.Option>
            ))}
          </Select>
        </div>

        {loading && (
          <Wrapper>
            <Spin size="large" />
          </Wrapper>
        )}

        {!loading && errorMessage && (
          <Wrapper>
            <Alert
              className="mb-2"
              type="error"
              message={errorMessage}
              showIcon />
          </Wrapper>
        )}

        {!loading && contents && (
          <Wrapper>
            <Alert
              className="mb-2"
              type="info"
              message={`${contents.length} contenus trouvés, vous pouvez les importer en cliquant sur le bouton "Importer"`}
              showIcon />

          {contentKind === 'contacts' && (
            <Alert
              className="mb-2"
              type="warning"
              message="Attention : Si des contacts avec le même email existent déjà dans votre base, les informations personnelles(prénom, nom etc...) seront écrasées par celles importées."
              showIcon />
          )}
          </Wrapper>
        )}
      </Modal>
    );
  }
}
