import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Avatar, Dropdown, Modal, Button, Spin, message } from 'antd';
import CampaignEmailEditor from './containers/Campaigns/Email/Editor';
import CampaignsEmailList from './containers/Campaigns/Email/List';
import CampaignSmsEditor from './containers/Campaigns/Sms/Editor';
import CampaignsSmsList from './containers/Campaigns/Sms/List';
import ContactsList from './containers/Audience/Contacts/List';
import ContactsEditor from './containers/Audience/Contacts/Editor';
import SegmentsList from './containers/Audience/Segments/List';
import SegmentsEditor from './containers/Audience/Segments/Editor';
import UsersList from './containers/Users/List';
import Header from './components/Header';
import Dashboard from './containers/Dashboard';
import Config from './containers/Config';
import Playground from './containers/Playground';
import { validate, asImmutable, required, minLength, maxLength, alphaNum, isEmail, isPhone, isIn, allowedKeys } from './services/validation';
import cookie from 'react-cookies';
import queryString from 'query-string';
import { getSlugParam, decodeTokenClaims } from './services/utils';
import UsersService, { storeMe } from './services/users';
import { getClient } from './services/client';

import 'moment/locale/fr';

import "./assets/styles/fontawesome/fontawesome.css";
import "./assets/styles/fontawesome/light.css";
import "./assets/styles/fontawesome/regular.css";
import "./assets/styles/fontawesome/solid.css";

message.config({
  top: 80,
  duration: 5
});

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const usersService = new UsersService();

const portalUrl = process.env.REACT_APP_PORTAL_URL;

class PrivateApp extends Component {
  state = {
    me: null
  }

  componentDidMount() {
    const handle401 = err => {
      const status = err && err.response && err.response.status;
      if (status === 401) {
        window.location.href = `${portalUrl}?error=campaign-unauthorized&slug=${getSlugParam()}&redirect_uri=${encodeURI(window.location.href)}`;
      }
    }
    usersService.me().then(me => {
      this.setState({ me });
    }).catch(handle401);
    getClient().then(client => {
      this.setState({ client });
    }).catch(handle401);
  }

  render() {
    const { me, client } = this.state;
    const path = window.location.pathname || "/";
    let selectedMenuKey = "1";
    if (path.startsWith("/contacts")) selectedMenuKey = "2";
    if (path.startsWith("/campaigns/email")) selectedMenuKey = "3";
    if (path.startsWith("/campaigns/sms")) selectedMenuKey = "4";
    if (path.startsWith("/campaigns/paper")) selectedMenuKey = "5";
    return (
      <Router>
        <Layout className="App">
          <Header me={me} client={client} />
          <Switch>
            <Route exact path="/playground" component={Playground} />
            <Route exact path="/config" component={Config} />

            <Route exact path="/" render={props => {
              return <Dashboard {...props} client={client} />
            }} />
            <Route exact path="/audience/contacts" component={ContactsList} />
            <Route exact path="/audience/contacts/new" component={ContactsEditor} />
            <Route exact path="/audience/contacts/edit/:id" render={(props) => {
              return <ContactsEditor {...props} key={props.match.params.id} />
            }}/>
            <Route exact path="/audience/segments" component={SegmentsList} />
            <Route exact path="/audience/segments/new" component={SegmentsEditor} />
            <Route exact path="/audience/segments/edit/:id" render={(props) => {
              return <SegmentsEditor {...props} key={props.match.params.id} />
            }}/>
            <Route exact path="/campaigns/email" component={CampaignsEmailList} />
            <Route exact path="/campaigns/email/new" component={CampaignEmailEditor} />
            <Route exact path="/campaigns/email/edit/:id" render={(props) => {
              // force to recreate the component
              return <CampaignEmailEditor {...props} key={props.match.params.id} />;
            }} />
            <Route exact path="/campaigns/sms" component={CampaignsSmsList} />
            <Route exact path="/campaigns/sms/new" component={CampaignSmsEditor} />
            <Route exact path="/campaigns/sms/edit/:id" render={(props) => {
              // force to recreate the component
              return <CampaignSmsEditor {...props} key={props.match.params.id} />;
            }} />
            <Route exact path="/campaigns/paper" render={() =>
              <Content className="App-content">
                contact
              </Content>
            } />
            <Route exact path="/users" component={UsersList} />
          </Switch>
        </Layout>
      </Router>
    );
  }
}

export default PrivateApp;
