import React, { Component, Fragment } from 'react';
import { Button, Icon, Alert } from 'antd';
import BottomBar from '../../../../../components/BottomBar';
import ConfigService from '../../../../../services/config';
import Form from '../../../../../components/Form';
import { required, minLength, maxLength } from '../../../../../services/validation';

const { Input, TextArea, Radio, Select, Checkbox } = Form;
const configService = new ConfigService();

export default class CampaignEditorSettings extends Component {
  state = {
    config: null
  }

  componentDidMount() {
    configService.getConfig().then(config => {
      this.setState({
        config: config || {}
      });
    });
  }

  handleSubmitSuccess = values => {
    this.props.onCampaignSettingsSubmit(values)
  }

  handleSubmitFailure = err => {
    console.log("campaign email saving error", err.toString());
  }

  render() {
    const { data } = this.props;
    if (!this.state.config) return null;
    const { senderSms: from } = this.state.config;
    const value = {
      from,
      ...data
    }

    return (
      <Form
        value={value}
        onSubmitSuccess={this.handleSubmitSuccess}
        onSubmitFailure={this.handleSubmitFailure}
        rules={[
          {
            path: ['name'],
            validators: [required, minLength(3)]
          },
          {
            path: ['from'],
            validators: [required, minLength(3), maxLength(11)]
          },
          {
            path: ['smsText'],
            validators: [required, minLength(3), maxLength(160)]
          }
        ]}
      >
        {form => {
          const smsTextLength = form.getValue(['smsText']) ? form.getValue(['smsText']).length : 0;
          // const smsTextUpperLimit = smsTextLength > 160 ? 320 : 160;
          const smsTextAbsoluteLimit = 160;
          const fromLength = form.getValue(['from']) ? form.getValue(['from']).length : 0;
          return (
            <Fragment>
              <Input
                label="Nom de la campagne *"
                path={['name']}
                form={form} />
              <Input
                label={
                  <span>
                    Expéditeur * {' '}
                    <span style={{color: fromLength > 11 ? 'red' : 'grey'}}>
                      {fromLength}/11 caractères
                    </span>
                  </span>
                }
                path={['from']}
                form={form} />
              <TextArea
                label={
                  <span>
                    Texte du SMS * {' '}
                    <span style={{color: smsTextLength > 160 ? 'red' : 'grey'}}>
                      {smsTextLength}/{smsTextAbsoluteLimit} caractères (1 message)
                    </span>
                  </span>
                }
                path={['smsText']}
                form={form} />
              <Checkbox
                label="Ceci est une campagne d'alerte"
                path={['isAlert']}
                form={form} />
              <Alert type="info" description={<Fragment>
                Les envois SMS "Marketing" doivent être effectués dans un
                <strong> créneau horaire spécifique </strong> :
                <br />
                - Du lundi au vendredi de <strong>08h00 à 20h00</strong>
                <br />
                - Le samedi de <strong>10h00 à 19h00</strong>
                <br />
                - L'envoi de SMS <strong> le dimanche et jours fériés
                est formellement interdit </strong>
                </Fragment>} className="mb-4" showIcon />
              <BottomBar
                right={
                  <div style={{ display: "inline-block" }}>
                    {form.isFormTouched && (
                      <Button type="primary" icon="right" onClick={() => {
                        form.handleSubmit();
                      }}>
                        audience
                      </Button>
                    )}
                    {!form.isFormTouched && (
                      <Button type="primary" icon="right" onClick={() => {
                        this.props.onNext()
                      }} disabled={!data}>
                        audience
                      </Button>
                    )}
                  </div>
                }
              />
            </Fragment>
          )
        }}
      </Form>
    );
  }
}
