import React from 'react';
import { guid } from './utils.js';
import ApiStore from './apiStore';

export default class Segments extends ApiStore {

  constructor() {
    super('segments');
  }

}
