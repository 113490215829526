import React from 'react';
import ButtonAction from 'components/ButtonAction';

export default ({
  fileUrl,
  withCropper,
  handleUploadClick,
  handleRemoveFile,
  toggleModalCropImage
}) => {
  return (
    <React.Fragment>
      {fileUrl && (
        <div className="ml-4">
          <ButtonAction
            title="upload"
            handleClick={handleUploadClick}
            iconClassName="fal fa-upload"
          />
          {withCropper && (
            <div className="mt-2">
              <ButtonAction
                title="recadrer"
                handleClick={toggleModalCropImage}
                iconClassName="fal fa-crop-alt"
              />
            </div>
          )}
          <div className="mt-2">
            <ButtonAction
              title="supprimer"
              handleClick={handleRemoveFile}
              iconClassName="fal fa-trash-alt"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
