import React, { Component } from 'react';
import { Alert, Button } from 'antd';
import BottomBar from '../../../../../components/BottomBar';
import ConfigService from '../../../../../services/config';
import Form from '../../../../../components/Form';
import { required, minLength, maxLength, isEmail } from '../../../../../services/validation';

const { Input, Checkbox } = Form;

export default class CampaignEditorSettings extends Component {
  state = {
    config: null
  }
  configService = new ConfigService();

  componentDidMount() {
    this.configService.getConfig().then(config => {
      this.setState({
        config: config || {}
      });
    });
  }

  handleSubmitSuccess = (values) => {
    this.props.onCampaignSettingsSubmit(values);
  }

  handleSubmitFailure = (err) => {
    console.log("campaign form errors", err.toString());
  }

  render() {
    const data = this.props.data || {};
    if (!this.state.config) return null;
    const { senderEmail: sender, from } = this.state.config;
    const value = {
      ...({ sender, from }),
      ...data
    }

    return (
      <Form
        value={value}
        onSubmitSuccess={this.handleSubmitSuccess}
        onSubmitFailure={this.handleSubmitFailure}
        rules={[
          {
            path: ['name'],
            validators: [required, minLength(3)]
          }, {
            path: ['subject'],
            validators: [required, minLength(3)]
          }, {
            path: ['sender'],
            validators: [required, minLength(3)]
          }, {
            path: ['from'],
            validators: [required, isEmail]
          }
        ]}
      >
        {form => (
          <>
            <Input
              label="Nom de la campagne *"
              path={['name']}
              form={form} />
            <Input
              label="Objet de l'email *"
              path={['subject']}
              form={form} />
            <Input
              label="Nom de l'expéditeur *"
              path={['sender']}
              form={form} />
            <Input
              label="Adresse email d'envoi *"
              path={['from']}
              form={form}
              disabled
            />
            {!!this.props.data.from || <Alert message="L'adresse email d'envoi doit être configurée par un administrateur." type="warning" showIcon />}
            <Checkbox
              label="Ceci est une campagne d'alerte"
              path={['isAlert']}
              form={form} />
            <BottomBar
              right={
                <div style={{ display: "inline-block" }}>
                  {form.isFormTouched && (
                    <Button type="primary" icon="right" onClick={() => {
                      form.handleSubmit();
                    }}>
                      template
                    </Button>
                  )}
                  {!form.isFormTouched && (
                    <Button type="primary" icon="right" onClick={() => {
                      this.props.onNext()
                    }} disabled={!data}>
                      template
                    </Button>
                  )}
                </div>
              }
            />
          </>
        )}
      </Form>
    );
  }
}
