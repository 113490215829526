import React, { Component, Fragment } from 'react';
import { Modal, Button, message } from 'antd';
import Form from 'components/Form';
import { required, isEmail } from 'services/validation';
import { getSlugParam } from 'services/utils';
import { inviteUser } from 'services/users';

const { Input } = Form;

export default class InvitationModal extends Component {

  handleSubmitSuccess = values => {
    const { onClose } = this.props;
    onClose();
    inviteUser(getSlugParam(), values.email).then(() => {
      message.success("L'invitation a bien été envoyée");
    }).catch(err => {
      console.log(err);
      message.warn("L'invitation a échoué, peut-être avez-vous déjà invité cette personne ?");
    })
  }

  handleSubmitFailure = errors => {
    console.log("invitation form failure", errors);
  }

  render() {
    const { visible, onClose } = this.props;
    const handleClose = (form) => {
      form.reset()
      onClose();
    }
    return (
      <Form
        className="mt-2"
        value={{}}
        onSubmitSuccess={this.handleSubmitSuccess}
        onSubmitFailure={this.handleSubmitFailure}
        rules={[{
          path: ['email'],
          validators: [ required, isEmail ]
        }]}
        submitRules={[]}
      >
      {form => (
        <Modal
        title="Saisissez l'email où envoyer l'invitation"
          visible={visible}
          onOk={() => form.handleSubmit()}
          onCancel={() => handleClose(form)}
          destroyOnClose={true}
        >
            <Fragment>
              <Input
                path={['email']}
                placeholder="example@email.com"
                form={form} />
            </Fragment>
        </Modal>
      )}
      </Form>
    );
  }

}
