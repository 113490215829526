import React, { Fragment } from 'react';
import Section from '..';
import { Button } from 'antd';
import Form from 'components/Form';

const { Input, RichText, ColorPicker, FileUpload } = Form

export const textImageAsMjml = ({ imageUrl, title, content: { html } = {}, linkTo, linkColor }) => ({
  "tagName": "mj-section",
  "attributes": {
    "full-width": "full-width",
    "background-color": "#fff",
  },
  "children": [
    {
      "tagName": "mj-column",
      "attributes": {
        "width": "50%",
        "vertical-align": "middle"
      },
      "children": [
        {
          tagName: "mj-image",
          attributes: {
            src: imageUrl,
            //width: `${width}px`,
            height: "245px",
            alt: "actualité",
            "padding-bottom": "20px",
            "padding-top": "20px"
          }
        }
      ]
    }, {
      tagName: "mj-column",
      attributes: {
        "width": "50%",
        "vertical-align": "middle"
      },
      "children": [
        {
          tagName: "mj-text",
          attributes: {
            align: "left",
            color: "#45474e",
            "font-size": "14px",
            "font-weight": "bold",
            "font-family": "Helvetica Neue",
          },
          content: title || ''
        }, {
          tagName: "mj-text",
          attributes: {
            align: "left",
            color: "#9da3a3",
            "font-size": "11px",
            "font-family": "Helvetica Neue",
          },
          content: html || ''
        },
        ...(linkTo && [{
          tagName: "mj-button",
          attributes: {
            align: "center",
            "background-color": linkColor,
            "color": "#fff",
            "border-radius": "24px",
            "font-size": "11px",
            href: linkTo,
            "padding-bottom": "45px",
            "padding-top": "10px",
            "font-family": "Helvetica Neue"
          },
          "content": "LIRE LA SUITE"
        }])
      ]
    }
  ]
})

export default class TextImage extends Section {
  constructor(props) {
    super(props, "Text + image");
  }

  asMjml = () => textImageAsMjml(this.props.attributes)

  renderConfigForm = (form, meta = {}) => {
    return (
      <Fragment>
        <FileUpload
          maxHeight={245}
          maxWidth={245}
          ratio={meta.imageRatio}
          path={['imageUrl']}
          label="Image"
          form={form} />
        <Input
          path={['title']}
          label="Titre"
          form={form} />
        <RichText
          path={['content']}
          label="Texte"
          form={form} />
        <Input
          path={['linkTo']}
          label="Lien du bouton"
          form={form} />
        <ColorPicker
          path={["linkColor"]}
          label="Couleur du bouton"
          form={form} />
      </Fragment>
    );
  }
}
