import ApiStore from './apiStore';
import axios from 'axios';
const { getSlugQuery, getTokenHeader } = require('./utils');

class CampaignsEmail extends ApiStore {

  constructor() {
    super('/campaigns/email');
  }

  send(id) {
    return axios.post(`${this.baseURL}/campaigns/email/${id}/send${getSlugQuery()}`, {}, {
      ...getTokenHeader()
    });
  }

  test(id, emails) {
    return axios.post(`${this.baseURL}/campaigns/email/${id}/test${getSlugQuery()}`, {
      emails
    }, {
      ...getTokenHeader()
    });
  }

  getCampaignStatistics(id) {
    return axios.get(`${this.baseURL}/campaigns/email/${id}/statistics${getSlugQuery()}`, {
      ...getTokenHeader()
    });
  }

  duplicate = (doc) => {
    const {
      createdAt,
      id,
      mailchimpCampaignId,
      mailchimpTemplateId,
      sendAt,
      sentAt,
      sentContactsCount,
      statistics,
      updatedAt,
      shouldIntersectSegments,
      ...duplicateCampaignEmail } = doc ;

      duplicateCampaignEmail.name = duplicateCampaignEmail.name + ' - Copie';
    return this.create(duplicateCampaignEmail);
  }

}

export default CampaignsEmail;
