import React, { Component } from 'react'
import { Upload, Icon, Modal } from 'antd'
import Filedrop from 'components/Filedrop'
import { uploadFile } from 'services/upload'
import { isDataURL, dataURLtoBlob, getDownscaledImage, getImageDimensions } from 'services/image'
import './index.css'

export default class ImageUploader extends Component {

  handleFileChange = async (file, croppedImage, name) => {
    const { path, form, maxWidth, maxHeight } = this.props;
    file = isDataURL(croppedImage) ? dataURLtoBlob(croppedImage) : file;
    // const { blob: downscaledImage, width, height } = await getDownscaledImage(file, { maxWidth, maxHeight })
    // const { url } = await uploadFile(downscaledImage, file.name)
    const { url } = await uploadFile(file, file.name);
    form.handleChange(path, url);
  }

  render() {
    const { path, form, ratio } = this.props
    const initialValue = form.getValue(path)
    return (
      <Filedrop initValue={initialValue} withCropper onFileChange={this.handleFileChange} ratio={ratio} />
    )
  }
}
