import React, { Component, Fragment } from 'react';
import { Layout, Button, Table, Divider, Dropdown, Icon, Menu, Tag } from 'antd';
import { Link } from 'react-router-dom';
import ApiTable from '../../../components/ApiTable';
import InvitationModal from './InvitationModal';

import UsersService from '../../../services/users';

const service = new UsersService();

const columns = [
  {
    title: 'Nom',
    key: 'name',
    render: (_, user) => user.username || ((user.firstname || '') + ' ' + (user.lastname || ''))
  },
  {
    title: "Email",
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Role',
    key: 'RoleId',
    render: (_, user) => user.Role && <Tag color="cyan">{user.Role.name}</Tag>
  },
];

const getParams = () => ({
  params: {
    include: [{ resource: 'Role' }]
  }
});

export default class UsersList extends Component {
  state = {
    invitationModalVisible: false
  }

  render() {
    const { invitationModalVisible } = this.state;
    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <Button type="primary" icon="user-add" className="mr-1" onClick={() => { this.setState({ invitationModalVisible: true }) }}>Inviter un utilisateur</Button>
          </div>
          <h1>Utilisateurs</h1>
        </div>
        <ApiTable
          service={service}
          getParams={getParams}
          basePath="/users"
          searchKeys={['firstname', 'lastname', 'email']}
          columns={columns}
          readOnly={true}
        />
        <InvitationModal visible={invitationModalVisible} onClose={() => { this.setState({ invitationModalVisible: false }) }} />
      </Layout>
    );
  }

}
