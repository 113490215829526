import React, { Component, Fragment } from "react"
import { Row, Col, Card, Button } from 'antd'
import BottomBar from 'components/BottomBar'
import ConfigNewsletterModal from '../ConfigNewsletterModal'

export default class TemplatesList extends Component {

  state = {}

  loadTemplate = (tpl, contents) => {
    let template = tpl;
    if (contents && contents.length) {
      template = {
        ...template,
        sections: template.sections.filter(section => {
          return section.kind !== 'textImage'
        }).concat(
          contents.map(content => ({
            kind: "textImage",
            attributes: {
              title: content.title || "Actualité",
              image: {
                url: content.image_url || "http://191n.mj.am/img/191n/1t/h2.jpg",
                width: "245",
                height: "245"
              },
              content: content.content,
              linkTo: content.data || "https://www.koba-civique.com/",
              linkColor: "#127ec2"
            }
          }))
        )
      }
    }
    this.props.onTemplateLoaded(template)
  }

  handleSelectNewsletterTemplate(tpl) {
    this.setState({
      configNewsletterModalVisible: true,
      selectedTemplate: tpl
    })
  }

  render() {
    const { templates } = this.props
    const { configNewsletterModalVisible, selectedTemplate } = this.state
    return (
      <Fragment>
        <div style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            {templates.map((tpl, i) => (
              <Col key={tpl.id} span={12}>
                <Card
                  key={i}
                  cover={<img alt={tpl.name} src={tpl.previewUrl} />}
                >
                  <Card.Meta
                    title={<h2 style={{display: "block", textAlign: "center", width: "100%"}}>{tpl.name}</h2>}
                    description={<Button style={{ width: '100%' }} onClick={() => this.loadTemplate(tpl)}>Choisir</Button>}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <ConfigNewsletterModal
            template={selectedTemplate}
            visible={configNewsletterModalVisible}
            onOk={(template, contents) => {
              this.loadTemplate(template, contents)
              this.setState({
                configNewsletterModalVisible: false
              })
            }}
            onCancel={() => this.setState({ configNewsletterModalVisible: false })} />
        </div>
      </Fragment>
    )
  }

}
