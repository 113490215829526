import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { decodeTokenClaims } from 'services/utils';
import logoMonSite from '../../assets/mon-site.svg';
import logoEspaceCollaboratif from '../../assets/espace-collaboratif.svg';
import logoMaCommunication from '../../assets/ma-communication.svg';
import { Layout, Avatar, Dropdown, Menu, Icon, Badge, Button } from 'antd';
import { getIn } from 'immutable';
import './index.css';

const SubMenu = Menu.SubMenu;

const switchLinks = () => {
  const slug = window.location.hostname.split('.')[0].split('-')[0];
  const urlMonSite = `https://${slug}-dashboard.koba-civique.com`;
  const urlEspaceCo = `https://${slug}-workspace.koba-civique.com`;
  return (
    <Menu style={{ width: 256 }}>
      <Menu.Item key="0" style={{ backgroundColor: '#5772D4', textAlign: 'center' }}>
        <Fragment><img src={logoMonSite} width="30"/>{' '}<span style={{ color: 'white', fontWeight: 600 }}>Mon site</span></Fragment>
      </Menu.Item>
      <Menu.Item key="1" style={{ height: '45px', paddingTop: '10px' }}>
        <a href={urlMonSite}>{slug}</a>
      </Menu.Item>
      <Menu.Item key="2" style={{ backgroundColor: '#FF7F48', textAlign: 'center' }}>
        <Fragment><img src={logoEspaceCollaboratif} width="30"/>{' '}<span style={{ color: 'white', fontWeight: 600 }}>Espace collaboratif</span></Fragment>
      </Menu.Item>
      <Menu.Item key="3" style={{ height: '45px', paddingTop: '10px' }}>
        <a href={urlEspaceCo}>{slug}</a>
      </Menu.Item>
    </Menu>
  );
}

const audienceMenu =
  <Menu>
    <Menu.Item key="0">
      <Link to={{ pathname: "/audience/contacts", search: window.location.search }}>Contacts</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={{ pathname: "/audience/segments", search: window.location.search }}>Filtres</Link>
    </Menu.Item>
  </Menu>;

const campaignsMenu =
  <Menu>
    <Menu.Item key="0">
      <Link to={{ pathname: "/campaigns/email", search: window.location.search }}>Email</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={{ pathname: "/campaigns/sms", search: window.location.search }}>SMS</Link>
    </Menu.Item>
  </Menu>;

const getName = (user) => {
  if (!user) return "";
  return user.username || user.firstname || user.email;
};

export default ({ me, client }) =>
  <Layout.Header className="Header">
    <Link to={{ pathname: "/", search: window.location.search }}>
      <div className="Header-logo">
        <img height="40" src="/ma-communication.svg" />
      </div>
    </Link>
    <div className="burger" style={{ display: 'inline-block' }}>
      <Link to={{ pathname: "/", search: window.location.search }}>
        <Button className="Header-menu" icon="dashboard">Dashboard</Button>
      </Link>
      <Dropdown overlay={audienceMenu} trigger={['click']}>
        <Button className="Header-menu" icon="user">Audience<Icon type="down" style={{ fontSize: '0.9rem', marginLeft: '3px' }} /></Button>
      </Dropdown>
      <Dropdown overlay={campaignsMenu} trigger={['click']}>
        <Button className="Header-menu" icon="notification">Campagnes<Icon type="down" style={{ fontSize: '0.9rem', marginLeft: '3px' }} /></Button>
      </Dropdown>
    </div>


    <div style={{ float: "right" }}>
      {/*
        <Button className="Header-button-alert">envoyer une alerte SMS</Button>
        <Dropdown overlay={switchLinks()} placement="bottomCenter" trigger={['click']}>
          <Avatar className="Header-icon--color" size="large" shape="square" style={{ backgroundColor: 'white' }}>
            <i className="Header-icon--color fas fa-th" style={{ marginTop: '0.4rem', fontSize: '27px', fontWeight: 'bold' }} />
          </Avatar>
        </Dropdown>
        {' '}
        <Badge dot>
          <Avatar className="Header-icon--background" style={{ verticalAlign: 'middle' }}>
            <i className="fas fa-bell" style={{ marginTop: '0.4rem', fontSize: '16px' }} />
          </Avatar>
        </Badge>
        {' '}
      */}
      <Link to={{ pathname: "/config", search: window.location.search }}>
        <Avatar className="Header-icon--color" size="large" shape="square" style={{ backgroundColor: 'white' }}>
          <i className="Header-icon--color fas fa-cog" style={{ marginTop: '0.4rem', fontSize: '27px', fontWeight: 'bold' }} />
        </Avatar>
      </Link>
      <Avatar size="large" icon="user" style={{ backgroundColor: "#4087c2", verticalAlign: 'middle', marginLeft: "2rem" }} />
      {'  '}
      <Dropdown overlay={
        <Menu>
          <Menu.Item key="0">
            <Link to="/users">Gestion des utilisateurs</Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3">Me déconnecter</Menu.Item>
        </Menu>
      } trigger={['click']}>
        <a className="ant-dropdown-link" href="#">
          {getName(me)} {client && `(${client.name})`}
          <Icon type="down" />
        </a>
      </Dropdown>
    </div>
  </Layout.Header>;
