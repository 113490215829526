const isDataURLRegex = /^\s*data:[a-z]+\/[a-z]+(;base64)?/i
export const isDataURL = s => !!s.match(isDataURLRegex)

export const fileToDataURL = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.addEventListener("load", function() {
    resolve(this.result)
  })
  reader.readAsDataURL(file)
})

export const dataURLtoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
}

export const getImageDimensions = imageSrc => new Promise((resolve, reject) => {
  const img = new Image()
  img.onload = function() {
    resolve({
      width: this.width,
      height: this.height
    })
    img.src = '';
  }
  img.src = imageSrc
})

export const getDownscaledImage = (file, { maxWidth, maxHeight }) => new Promise(async (resolve, reject) => {
  const dataURL = await fileToDataURL(file)
  const imageDimensions = await getImageDimensions(dataURL)
  let { width, height } = imageDimensions
  const ratio = width / height
  if (maxHeight && height > maxHeight) {
    height = maxHeight
    width = ratio * height
  }
  if (maxWidth && width > maxWidth) {
    width = maxWidth
    height = width / ratio
  }
  if (width === imageDimensions.width || height === imageDimensions.height) {
    resolve({ blob: file, width, height })
    return;
  }
  // resize image thanks to canvas
  const img = new Image()
  img.onload = function() {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    img.width = width
    img.height = height
    const ctx = canvas.getContext("2d")
    ctx.drawImage(this, 0, 0, width, height)
    canvas.toBlob(blob => resolve({ blob, width, height }))
    img.src = '';
  }
  img.src = dataURL
})
