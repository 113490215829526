import React, { Fragment, Component } from 'react';
import { Modal, Upload, Button, Icon, message } from 'antd';
import { getSlugQuery, getTokenHeader } from 'services/utils';

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

export default class ImportModal extends Component {
  handleChange = info => {
    const { onClose } = this.props;
    if (info.file.status === 'done') {
      if (info.file.response.created) {
        message.success(`${info.file.response.created} nouveau(x) contact(s) importé(s) avec succès sur un total de ${info.file.response.total}`);
      } else {
        message.error("Aucun contact n'a été importé. Veuillez vérifier les valeurs insérées dans le fichier importé.")
      }
      onClose();
    } else if (info.file.status === 'error') {
      message.error(`l'import de ${info.file.name} a échoué`);
    }
  }
  render() {
    const { visible, onClose } = this.props;
    return (
      <Modal
        visible={visible}
        title="Import de contacts"
        onCancel={onClose}
        footer={[
          <Button key="close" onClick={onClose}>OK</Button>
        ]}
      >
        <Upload.Dragger
          name="file"
          action={`${apiUrl}/contacts/import${getSlugQuery()}`}
          {...(getTokenHeader())}
          onChange={this.handleChange}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Cliquez ou faites glisser un fichier CSV</p>
        </Upload.Dragger>
      </Modal>
    )
  }
}
