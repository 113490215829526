import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './index.css'

export default class ModalCropImage extends Component {
  state = {
    src: null,
    image: null,
    loading: false,
    crop: {
      x: 0,
      y: 0,
      width: 100,
      height: 100
    }
  }

  componentDidMount() {
    let file;
    const { image, imageSource } = this.props;

    if (!image) {
      let name = Date.now().toString() + '.jpeg';
      file = new File([imageSource], name, {
        type: 'image/jpeg',
        lastModified: Date.now()
      });
    } else {
      file = image;
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () =>
        this.setState({
          src: imageSource
        }),
      false
    );
    reader.readAsDataURL(file);
  }

  onCropChange = crop => {
    this.setState({ crop });
  };

  handleCropValidate = () => {
    const { crop, src } = this.state;
    const { handleSubmitCrop, id } = this.props;
    this.setState({ loading: true });

    this.addImageProcess(src, this.refImageCrop, crop).then(
      canvas => {
        canvas.toBlob(
          blob => {
            const croppedImage = canvas.toDataURL('image/jpeg');
            this.setState({ loading: false });
            const name = Date.now().toString() + '.jpeg';
            const file = new File([blob], name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            handleSubmitCrop(croppedImage, file, id);
          },
          'image/jpeg',
          0.95
        );
      }
    );
  };

  addImageProcess = (url, srcImage, pixelCrop) => {
    return new Promise((resolve, reject) => {
      let img = new Image();

      img.crossOrigin = 'Anonymous';
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const targetX = (srcImage.width * pixelCrop.x) / 100;
      const targetY = (srcImage.height * pixelCrop.y) / 100;
      const targetWidth = (srcImage.width * pixelCrop.width) / 100;
      const targetHeight = (srcImage.height * pixelCrop.height) / 100;
      img.onload = function() {
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(
          img,
          targetX,
          targetY,
          targetWidth,
          targetHeight,
          0,
          0,
          targetWidth,
          targetHeight
        );
        resolve(canvas);
      };

      img.src = url;
    });
  };

  render() {
    const { src, crop, loading } = this.state;
    const { toggleModalCropImage, modalCropImageActive } = this.props;
    if (!src) return null;

    return (
      <>
        <Modal
          visible={modalCropImageActive}
          title="Recadrer l'image"
          toggle={() => toggleModalCropImage(null)}
          size="lg"
          destroyOnClose={true}
          className="modal-crop"
          footer={
            <>
              <div className=" mt-4 mt-md-0">
                <Button
                  type="danger"
                  onClick={() => toggleModalCropImage(null)}
                  className="btn btn-outline-primary float-left mr-2 "
                >
                  ANNULER
                </Button>
                <Button
                  type="primary"
                  onClick={this.handleCropValidate}
                  className="btn btn-primary float-right ml-2"
                  disabled={loading}
                >
                  VALIDER
                </Button>
              </div>
            </>
          }
      >
      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          onImageLoaded={() => {}}
          onComplete={() => {}}
          onChange={this.onCropChange}
        />
      )}
      <img
        src={src}
        style={{ display: 'none' }}
        ref={img => {
                this.refImageCrop = img;
              }}
        alt="recadrée"
      />
        </Modal>
      </>
    );
  }
}
