import React, { Component, Fragment } from 'react';
import { Select, Row, Col, Card, Icon, Alert } from 'antd';
import SegmentsService from 'services/segments';
import ContactsService from 'services/contacts';
import { getClient } from 'services/client';
import { buildWhere } from 'services/filters';

const service = new ContactsService();

export default class AudienceForm extends Component {
  state = {
    campaign: this.props.value,
    existingSegments: [],
    targetsCount: '...',
    contactsCount: '...'
  }
  segmentsService = new SegmentsService();

  componentDidMount() {
    this.segmentsService.get().then(resp => this.setState({
      existingSegments: resp.rows
    }, () => {
      this.fetchTargetsCountAndCredits();
    }));
  }

  fetchTargetsCountAndCredits = async () => {
    const { campaignKind, onFetchedTargetsCountAndCredits } = this.props;
    const nonEmptyField = campaignKind === 'email' ? 'email' : 'phone';
    const { campaign: { Segments, shouldIntersectSegments } = {}, existingSegments } = this.state;
    const segments = Segments && Segments.map(({ id }) => existingSegments.find(s => s.id === id));
    const logicalCombinator = shouldIntersectSegments ? '$and' : '$or';
    const where = (segments && segments.length > 0) ? { [logicalCombinator]: segments.map(s => buildWhere(s.filters)) } : null;
    const targetsCount = await service.get({
      params: {
        where: {
          $and: [
            { [nonEmptyField]: { $ne: '' } },
            ...(nonEmptyField === 'phone' ? [{ optoutSmsAt : { $eq: null } }] : []),
            ...(nonEmptyField === 'email' ? [{ optoutEmailAt : { $eq: null } }] : []),
            where
          ]
        }
      }, limit: 0
    }).then(resp => resp.count);
    const contactsCount = await service.get().then(({ count }) => count)
    const credits = await getClient().then(c => nonEmptyField === 'phone' ? c.smsCredits : c.emailCredits) || 0;
    this.setState({
      targetsCount,
      contactsCount,
      credits
    });
    if (onFetchedTargetsCountAndCredits) {
      onFetchedTargetsCountAndCredits(targetsCount, credits);
    }
  }

  handleAudienceSelectChange = (selected) => {
    this.setState(state => ({
      campaign: {
        ...state.campaign,
        Segments: selected && selected.map(id => ({ id })),
      }
    }), () => {
      this.props.onChange(this.state.campaign);
      this.fetchTargetsCountAndCredits();
    });
  }

  handleShouldIntersectSegments = shouldIntersectSegments => {
    this.setState(state => ({
      campaign: {
        ...state.campaign,
        shouldIntersectSegments
      }
    }), () => {
      this.props.onChange(this.state.campaign);
      this.fetchTargetsCountAndCredits();
    });
  }

  render() {
    const { existingSegments, campaign: { shouldIntersectSegments, Segments }, targetsCount, credits , contactsCount } = this.state;
    if (targetsCount === '...') return null;
    const selectedSegments = (Segments && Segments.length > 0 && Segments.map(({ id }) => id)) || [];
    return (
      <Fragment>
        <h2 style={{ marginTop: "20px" }}>Combinez des filtres</h2>
        <div className="ant-form-item">
          <div className="ant-form-item-label">
            <label>Les contacts de l{"'"}audience doivent respecter</label>
          </div>
          <Select size="large" value={!!shouldIntersectSegments} onChange={this.handleShouldIntersectSegments} style={{width: "100%"}}>
            <Select.Option value={false}>au moins un des filtres sélectionnés (OU logique)</Select.Option>
            <Select.Option value={true}>tous les filtres sélectionnés à la fois (ET logique)</Select.Option>
          </Select>
        </div>
        <div className="ant-form-item">
          <div className="ant-form-item-label">
            <label>Utilisez des filtres enregistrés</label>
          </div>
          <Select
            mode="multiple"
            size="large"
            placeholder="Sélectionner vos filtres"
            value={selectedSegments}
            onChange={selected => this.handleAudienceSelectChange(selected)}
            style={{ width: '100%' }}
          >
            {existingSegments.map(segment => (
              <Select.Option key={segment.id} value={segment.id}>{segment.name}</Select.Option>
            ))}
          </Select>
        </div>
        <Alert message={
          <Fragment>
            Votre audience cible une base estimée de {targetsCount} {(Number.isInteger(targetsCount) && targetsCount > 1) ? 'contacts' : 'contact'} sur les {contactsCount} contacts existants
            <br />
            {(credits < targetsCount) && <span>Vous n'avez pas assez de crédits pour envoyer la campagne ({credits} restant)</span>}
            {(credits >= targetsCount) && <span>Il vous restera {credits - targetsCount} crédits disponibles après l'envoi de la campagne (crédits actuels : {credits})</span>}
          </Fragment>
        } type={credits >= targetsCount ? "success" : "error"} />
      {this.props.campaignKind === 'sms' && (
        <Alert style={{marginTop: '20px'}} message={
        <>
          Attention, tout numéro invalide ne sera pas décompté de votre crédit.<br />Les statistiques concernent uniquement les SMS envoyés.
        </>
        } type="warning" showIcon/> 
      )}
      </Fragment>
    );
  }
}
