import React, { Component, Fragment } from 'react';
import { Modal, Select, DatePicker, Spin, Button } from 'antd';
import Form from '../../../../../../components/Form';
import ConfigService from '../../../../../../services/config';
import Users from 'services/users';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/fr_FR';

const usersService = new Users();

export default class ConfigNewsletterModal extends Component {
  state = {
    contentKind: null,
    contentFilter: null,
    since: null,
    config: null
  }
  configService = new ConfigService();

  componentWillMount = () => {
    this.configService.getConfig().then(config => {
      this.setState({
        config
      });
    })
  }

  fetch = () => {
    const { config, siteSelected, contentKind, contentFilter, since: contentFilterSinceDate } = this.state;
    if (!config || !contentKind) return null;
    this.setState({
      loading: true
    });
    usersService.siteContents(siteSelected.slug, contentKind, contentFilter, contentFilterSinceDate).then(contents => {
      this.setState({
        loading: false,
        isFormTouched: false,
        contents
      });
    })
  }

  handleOK = () => {
    const { contents, isFormTouched, siteSelected } = this.state;
    const { visible } = this.props;
    if (contents && !isFormTouched) {
      this.props.onOk(siteSelected, contents);
    } else {
      this.fetch();
    }
  }

  render = () => {
    const { visible, userSites } = this.props;
    const { siteSelected, contentKind, contentFilter, since, loading, contents, isFormTouched } = this.state;
    return (
      <Modal title={`Importer les contenus d'un site Koba`}
        visible={visible}
        onCancel={this.props.onCancel}
        footer={
          <Fragment>
            <Button onClick={this.props.onCancel}>Annuler</Button>
            <Button
              type="primary"
              onClick={this.handleOK}
              disabled={!siteSelected || !contentKind || !contentFilter || (contentFilter === 'since' && !since) || loading}
            >
              {(contents && !isFormTouched) ? "Importer" : "Chercher"}
            </Button>
          </Fragment>
        }
      >
        <div className="ant-form-item">
          <div className="auth-form-item-label">
            <label>Choisir un site</label>
          </div>
          <Select size="large" style={{width: "100%"}} value={siteSelected ? siteSelected.slug : null}
            onChange={v => {
              this.setState({
                siteSelected: userSites.find(site => site.slug === v)
              });
            }}>
            {userSites && userSites.map(site => (
              <Select.Option key={site.slug} value={site.slug}>{site.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className="ant-form-item">
          <div className="ant-form-item-label">
            <label>Type de contenu</label>
          </div>
          <Select size="large" style={{width: "100%"}} value={contentKind}
            onChange={v =>
              this.setState({
                contentKind: v,
                isFormTouched: true
              })
            }>
            <Select.Option value="news">Actualités</Select.Option>
            <Select.Option value="events">Evénements</Select.Option>
          </Select>
        </div>
        <div className="ant-form-item">
          <div className="ant-form-item-label">
            <label>Quantité à remonter dans la newsletter</label>
          </div>
          <Select size="large" style={{width: "100%"}} value={contentFilter}
            onChange={v =>
              this.setState({
                contentFilter: v,
                isFormTouched: true
              })}>
            <Select.Option value="last-3">Les trois derniers</Select.Option>
            <Select.Option value="last-4">Les quatre derniers</Select.Option>
            <Select.Option value="last-5">Les cinq derniers</Select.Option>
            <Select.Option value="since">Depuis le</Select.Option>
          </Select>
          <br />
          {contentFilter === 'since' && (
            <DatePicker style={{width: "100%"}} locale={locale}
              onChange={v =>
                this.setState({
                  since: v,
                  isFormTouched: true
                })
              } />
          )}
        </div>
        {loading && (
          <div style={{ background: "rgba(0,0,0,0.05)", width: "100%", textAlign: "center", borderRadius: 5, padding: "30px 50px" }}>
            <Spin size="large" />
          </div>
        )}
        {!loading && !isFormTouched && contents && (
          <div style={{ background: "rgba(0,0,0,0.05)", width: "100%", textAlign: "center", borderRadius: 5, padding: "30px 50px" }}>
            <strong style={{fontSize: "16px"}}>{contents.length} contenus trouvés</strong>
          </div>
        )}
      </Modal>
    );
  }
}
