import React, { Component, Fragment } from 'react'
import TitleWithLogo, { titleWithLogoAsMjml } from './Section/TitleWithLogo'
import HeaderImage, { headerImageAsMjml } from './Section/HeaderImage'
import HeroText, { heroTextAsMjml } from './Section/HeroText'
import Text, { textAsMjml } from './Section/Text'
import TextImage, { textImageAsMjml } from './Section/TextImage'
import ImageText, { imageTextAsMjml } from './Section/ImageText'
import { buildEmailMjml } from 'services/emailsTemplates'
import PreviewButton from './PreviewButton'
import { Button, Card, Modal, Alert, Row, Col, Affix, Icon } from 'antd'
import BottomBar from 'components/BottomBar'
import TemplatesList from './TemplatesList'
import ConfigNewsletterModal from './ConfigNewsletterModal';
import Users from 'services/users';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './index.css'

const mapSectionsToMjml = (sections) => {
  if (! sections) return []
  return sections.map(s => {
    if (s.kind === "titleWithLogo") return titleWithLogoAsMjml(s.attributes)
    if (s.kind === "headerImage") return headerImageAsMjml(s.attributes)
    if (s.kind === "heroText") return heroTextAsMjml(s.attributes)
    if (s.kind === "text") return textAsMjml(s.attributes)
    if (s.kind === "textImage") return textImageAsMjml(s.attributes)
    if (s.kind === "imageText") return imageTextAsMjml(s.attributes)
  })
}

const usersService = new Users();

export default class CampaignEditorTemplate extends Component {
  state = {
    sectionsMjml: [],
    userSites: null
  }

  componentWillMount() {
    this.resetState()
  }

  componentDidMount() {
    usersService.sites().then(userSites => {
      this.setState({
        userSites
      });
    })
  }

  resetState() {
    let { data } = this.props
    this.setState({
      template: data || { sections: [] },
      sectionsMjml: (data && data.sections) ? mapSectionsToMjml(data.sections) : [],
      isFormTouched: false
    })
  }

  moveSection = (i, inc) => {
    this.setState((state, props) => {
      const { template } = state
      const sections = template.sections
      const nextSections = sections.map((s, sIndex) => {
        if (sIndex === i) return sections[i + inc]
        if (sIndex === i + inc) return sections[i]
        return s
      })
      return {
        template: {
          ...template,
          sections: nextSections
        },
        sectionsMjml: mapSectionsToMjml(nextSections),
        isFormTouched: true
      }
    })
  }

  deleteSection = (i) => {
    this.setState((state, props) => {
      const { template } = state
      const sections = template.sections
      const nextSections = sections.filter((s, sIndex) => sIndex !== i)
      return {
        template: {
          ...template,
          sections: nextSections
        },
        sectionsMjml: mapSectionsToMjml(nextSections),
        isFormTouched: true
      }
    })
  }

  handleConfigSave = (i, config) => {
    this.setState((state, props) => {
      const sections = state.template.sections
      const nextSections = sections.map((s, sIndex) => sIndex === i ? { ...s, attributes: config } : s )
      return {
        template: {
          ...state.template,
          sections: nextSections
        },
        sectionsMjml: mapSectionsToMjml(nextSections),
        isFormTouched: true
      }
    })
  }

  insertSection = (section, i) => {
    this.setState((state, props) => {
      const { template, sectionsMjml } = state
      const { sections = [] } = template
      const nextSections = [
        ...sections.slice(0, i),
        section,
        ...sections.slice(i)
      ]
      return {
        template: {
          ...template,
          sections: nextSections
        },
        sectionsMjml: mapSectionsToMjml(nextSections),
        isFormTouched: true
      }
    })
  }

  handleSubmit = (stepInc = 1) => {
    const { template, sectionsMjml } = this.state
    this.props.onCampaignTemplateSubmit({
      ...template,
      mjml: buildEmailMjml(sectionsMjml.filter(sm => sm != null), true)
    }, stepInc);
  }

  handleTemplateLoaded = (template, contents) => {
    this.setState({
      template,
      sectionsMjml: mapSectionsToMjml(template.sections),
      changeTemplateModalVisible: false,
      isFormTouched: true
    })
  }

  handleImportSiteContents = (site, contents) => {
    if (!contents || contents.length === 0) return;
    let { template } = this.state;
    template = {
      ...template,
      sections: template.sections.filter(section => {
        return section.kind !== 'textImage'
      }).concat(
        contents.map(content => {
          const fixedContentLink = content.data && content.data.replace('/events', '/evenements').replace('/news', '/actualites');
          return {
            kind: "textImage",
            attributes: {
              title: content.title || "Actualité",
              imageUrl: content.image_url || "http://191n.mj.am/img/191n/1t/h2.jpg",
              content: { html: content.content },
              linkTo: (fixedContentLink && `${site.url}${fixedContentLink}`) || "https://www.koba-civique.com/",
              linkColor: "#127ec2"
            }
          };
        })
      )
    }
    this.setState({
      template,
      sectionsMjml: mapSectionsToMjml(template.sections),
    });
    this.showImportSiteContentsModal(false);
  }

  showChangeTemplateModal = (bool) => {
    this.setState({
      changeTemplateModalVisible: bool
    });
  }

  showYouShouldBuyASiteModal = (bool) => {
    this.setState({
      youShouldBuyASiteModalVisible: bool
    });
  }

  showImportSiteContentsModal = (bool) => {
    this.setState({
      importSiteContentsModalVisible: bool
    });
  }

  onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === 'library' || source.droppableId === 'sections') {
      this.setState((state, props) => {
        const { template, sectionsMjml } = state
        const { sections = [] } = template
        let section;
        if (source.droppableId === 'library') {
          section = template.library.find((_, i) => i === source.index);
        } else {
          section = sections.find((_, i) => i === source.index);
        }
        const nextSections = Array.from(sections);
        if (source.droppableId === 'sections') {
          // if user is moving a section (and not picking one from the library on the left)
          // => remove the section from its previous place
          nextSections.splice(source.index, 1);
        }
        nextSections.splice(destination.index, 0, section);
        return {
          template: {
            ...template,
            sections: nextSections
          },
          sectionsMjml: mapSectionsToMjml(nextSections),
          isFormTouched: true
        }
      });
    }
  }

  render() {
    const {
      template, sectionsMjml, isFormTouched, userSites,
    } = this.state;
    const { templates } = this.props;

    if (! template.id) {
      return (
        <Fragment>
          <h3>Choisissez un template</h3>
          <TemplatesList templates={templates} onTemplateLoaded={this.handleTemplateLoaded} />
          <BottomBar
            left={
              <Button type="primary" icon="left" onClick={() => this.props.onPrevious()}>configuration</Button>
            }
            right={
              <div style={{ display: "inline-block" }}>
                <Button icon="right" type="primary" disabled> audience</Button>
              </div>
            }
          />
        </Fragment>
      )
    }

    const mjml = buildEmailMjml(sectionsMjml.filter(sm => sm != null))
    const sectionProps = (section, i) => ({
      attributes: section.attributes,
      meta: section.meta,
      //key: JSON.stringify(section), // iframe updates are complex, here iframes are rendered only once when a section is added or moved
      first: i === 0,
      last: (i + 1) === template.sections.length,
      onUp: () => this.moveSection(i, -1),
      onDown: () => this.moveSection(i, 1),
      onDelete: () => this.deleteSection(i),
      handleConfigSave: (config) => this.handleConfigSave(i, config),
      isConfigModalOpen: (bool) => this.setState({ isConfigModalOpen: bool })
    })

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {/* TODO insert template params form */}
        <Row>
          <Col span={8} style={{ minHeight: 400 }}>
            <Affix offsetTop={80}>
              <Droppable droppableId="library" isDropDisabled={true}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{paddingLeft: 20, paddingRight: 20}}
                  >
                    {template.library.map((block, i) => (
                      <Draggable key={i} draggableId={block.kind} index={i} isDragDisabled={this.state.isConfigModalOpen}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card className="library-block">
                              <Card.Meta title={
                                <Fragment>
                                  <Icon type="drag" style={{float: "right"}} />
                                  {(block.kind === "titleWithLogo") && (
                                    <Fragment>
                                      <Icon type="font-size" className="mr-1" />
                                      <span>Titre et logo</span>
                                    </Fragment>
                                  )}
                                  {(block.kind === "headerImage") && (
                                    <Fragment>
                                      <Icon type="picture" className="mr-1" />
                                      <span>Image de fond</span>
                                    </Fragment>
                                  )}
                                  {(block.kind === "heroText") && (
                                    <Fragment>
                                      <Icon type="highlight" className="mr-1" />
                                      <span>Texte mis en avant</span>
                                    </Fragment>
                                  )}
                                  {(block.kind === "text") && (
                                    <Fragment>
                                      <Icon type="align-center" className="mr-1" />
                                      <span>Texte</span>
                                    </Fragment>
                                  )}
                                  {(block.kind === "textImage") && (
                                    <Fragment>
                                      <Icon type="pic-left" className="mr-1" />
                                      <span>Image et texte</span>
                                    </Fragment>
                                  )}
                                  {(block.kind === "imageText") && (
                                    <Fragment>
                                      <Icon type="pic-right" className="mr-1" />
                                      <span>Texte et image</span>
                                    </Fragment>
                                  )}
                                </Fragment>
                              } />
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Affix>
          </Col>
          <Col span={16}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {template.sections.map((section, i) => (
                    <Draggable key={JSON.stringify(section) + i} draggableId={JSON.stringify(section) + i} index={i} isDragDisabled={this.state.isConfigModalOpen}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {section.kind === "titleWithLogo" &&
                            <TitleWithLogo {...sectionProps(section, i)} />}
                          {section.kind === "headerImage" &&
                            <HeaderImage {...sectionProps(section, i)} />}
                          {section.kind === "heroText" &&
                            <HeroText {...sectionProps(section, i)} />}
                          {section.kind === "text" &&
                            <Text {...sectionProps(section, i)} />}
                          {section.kind === "textImage" &&
                            <TextImage {...sectionProps(section, i)} />}
                          {section.kind === 'imageText' &&
                            <ImageText {...sectionProps(section, i)} />}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div style={{maxWidth: "992px", margin: "auto", marginTop: "20px"}}>
              <div style={{marginTop: "20px"}}>
                <Modal
                  title="Choisir un nouveau template"
                  width={700}
                  visible={this.state.changeTemplateModalVisible}
                  onCancel={() => this.showChangeTemplateModal(false)}
                  footer={
                    <Button onClick={() => this.showChangeTemplateModal(false)}>annuler</Button>
                  }
                >
                  <Alert message={
                    <Fragment>
                      <Icon type="warning" className="mr-1"/>
                      <span>Attention vous perdrez le template en cours d'édition</span>
                    </Fragment>
                  } type="error" />
                  <TemplatesList templates={templates} onTemplateLoaded={this.handleTemplateLoaded} />
                </Modal>
                <Modal
                  title="Vous n'avez pas encore de site Koba Civique"
                  width={700}
                  visible={this.state.youShouldBuyASiteModalVisible}
                  onCancel={() => this.showYouShouldBuyASiteModal(false)}
                  footer={
                    <Button onClick={() => this.showYouShouldBuyASiteModal(false)}>annuler</Button>
                  }
                >
                  <a href="https://www.koba-civique.com/" target="_blank"><Button>En savoir plus sur les sites Koba Civique</Button></a>
                </Modal>
                <ConfigNewsletterModal
                  visible={this.state.importSiteContentsModalVisible}
                  userSites={this.state.userSites}
                  onCancel={() => this.showImportSiteContentsModal(false)}
                  onOk={this.handleImportSiteContents}
                />
                {userSites && userSites.length > 0 && template.kind === 'newsletter' && (
                  <Button icon="upload" type="info" onClick={() => this.showImportSiteContentsModal(true)} className="mr-2"> Importer le contenu de mon site </Button>
                )}
                {!(userSites && userSites.length > 0) && template.kind === 'newsletter' && (
                  <Button icon="upload" type="info" onClick={() => this.showYouShouldBuyASiteModal(true)} className="mr-2"> Importer le contenu de mon site </Button>
                )}
                <Button icon="rollback" type="danger" onClick={() => this.showChangeTemplateModal(true)}> Choisir un autre template </Button>
              </div>

              <BottomBar
                left={
                  <span>
                    {isFormTouched && (
                      <Button type="primary" icon="left" onClick={() => this.handleSubmit(-1)}>configuration</Button>
                    )}
                    {!isFormTouched && (
                      <Button type="primary" icon="left" onClick={this.props.onPrevious}>configuration</Button>
                    )}
                  </span>
                }
                right={
                  <div style={{ display: "inline-block" }}>
                    {template && template.id && <PreviewButton mjml={mjml} />}
                    {isFormTouched && (
                      <Button type="primary" icon="right" onClick={() => this.handleSubmit()}> audience </Button>
                    )}
                    {!isFormTouched && (
                      <Button type="primary" icon="right" onClick={this.props.onNext}> audience </Button>
                    )}
                  </div>
                }
              />
            </div>
          </Col>
        </Row>
      </DragDropContext>
    )
  }
}
