import React, { useState } from 'react';
import LandingPage from './LandingPage'
import PublicApp from 'providers/PublicApp'
import { message } from "antd";
import moment from "moment";
import ContactsService from "services/contacts";

const contactsService = new ContactsService();
const REQUIRED_FIELDS = ['gender', 'firstname', 'lastname', 'authorization'];
const AT_LEAST_ONE_FIELD = ['phone', 'email'];
const VALIDATION_FIELDS = [...REQUIRED_FIELDS, ...AT_LEAST_ONE_FIELD];

export default (props) => {
  const [contact, setContact] = useState({
    gender: "",
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    addressLine1: "",
    addressPostalCode: "",
    addressCity: "",
    ageCategory: "",
    authorization: false
  });
  const [errors, setErrors] = useState([]);

  const handleChange = e => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const updatedContact = { ...contact };

    updatedContact[name] = value;
    setContact(updatedContact)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      contactsService.create(buildDataToSend())
      .then(c => props.history.push('/confirmation'))
      .catch(_ => message.error('Une erreur est survenue. Veuillez rééssayer'))
    }
  };

  const displayPhoneTooltip = () => { message.info('Format accepté : 0(6/7)12345678') };

  const buildDataToSend = () => {
    let data = { ...contact };
    delete data.authorization;

    return {
      ...data,
      ...(data.email && { optinEmailAt: moment() } || { email: null }),
      ...(data.phone && { optinSmsAt: moment() }) || { phone: null },
    };
  };

  const filterMissingRequiredFields = (values) => Object.entries(values)
      .filter(([key, _value]) => VALIDATION_FIELDS.includes(key))
      .reduce((acc, [key, value]) => {
        if (value) return acc;

        return [...acc, key]
      }, []);

  const filterErrors = (missingFields) => {
    if (AT_LEAST_ONE_FIELD.every((value) => missingFields.includes(value))) {
      message.error('Veuillez remplir un numéro de téléphone ou une adresse email.');
      return missingFields;
    } else return missingFields.filter((value) => !AT_LEAST_ONE_FIELD.includes(value))
  };

  /*
   Check if firstname, lastname, gender and (phone OR mail) is filled
   Otherwise return the name of the missing field to apply has-error classname
   */
  const validate = () => {
    const missingFields = filterMissingRequiredFields(contact);
    const filteredErrors =  filterErrors(missingFields);
    setErrors(filteredErrors);
    return !filteredErrors.length // if array empty true, else false.
  };

  return <PublicApp.Consumer>
    {({ ...data }) =>
      <LandingPage {...data} {...props}
                   contact={contact}
                   handleChange={handleChange}
                   handleSubmit={handleSubmit}
                   displayPhoneTooltip={displayPhoneTooltip}
                   errors={errors}
      />
    }
  </PublicApp.Consumer>
}
