import React, { Component, Fragment } from 'react';
import Form from '../../components/Form';
import Select from '../../components/Form/Select';
const { Button } = require('antd');
const { required, minLength } = require('../../services/validation');


export default class Playground extends Component {
  render() {
    const rules = [
      {
        path: ['username'],
        validators: [required, minLength(5)]
      }
    ];

    return (
      <Form
        onSubmitSuccess={_ => {}}
        onSubmitFailure={_ => {}}
        rules={rules}
      >
        {formInst =>
          <Fragment>
            <Select label="gender" path={['gender']} form={formInst}
              options={[
                {value: "male", label: "Homme"},
                {value: "female", label: "Femme"}
              ]}
              style={{width: "50%"}}
              className="albert"/>
            <Button type="primary" onClick={_ => formInst.handleSubmit()}>valider</Button>
          </Fragment>
        }
      </Form>
    )
  }
}
