import React, { Component } from 'react'
import FileDropButtons from './FileDropButtons'
import ModalCropImage from 'components/ModalCropImage'
import PropTypes from 'prop-types'
import './index.css'

const acceptance = {
  images: 'image/*',
  pdf: 'application/pdf'
}

export default class Filedrop extends Component {
  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    withCropper: PropTypes.bool
  }

  state = {
    fileUrl: '',
    image: null,
    modalCropImageActive: false
  }

  setImageData = (fileUrl = '', file = null) => {
    this.setState({ fileUrl: fileUrl, image: file })
    const imgStyle = { backgroundImage: `url( '${fileUrl}' )` }
    this.setState({ imgStyle })
  }

  handleRemoveFile = _ => {
    this.setImageData(null)
  }

  handleFileChange = event => {
    const file = event.target.files[0]
    const name = event.target.name
    const fileUrl = URL.createObjectURL(file)

    // image case
    if (file && file.type.includes('image')) this.setImageData(fileUrl, file)
    // file case
    else this.setState({ fileUrl: event.target.name })

    if (this.props.onFileChange) this.props.onFileChange(file, fileUrl, name)
  }

  toggleModalCropImage = () =>
    this.setState(state => ({
      modalCropImageActive: !state.modalCropImageActive
    }))

  handleSubmitCrop = (croppedImage, file) => {
    this.toggleModalCropImage()
    this.setImageData(croppedImage)
    return this.props.onFileChange(file, croppedImage, this.props.name)
  }

  componentWillMount() {
    this.setImageData(this.props.initValue)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initValue && prevProps.initValue !== this.props.initValue) {
      if (this.props.initValue === null) {
        this.setImageData('')
      } else {
        this.setImageData(this.props.initValue)
      }
    }
  }

  render() {
    const { fileUrl, imgStyle, image, modalCropImageActive } = this.state
    const { required, name, withCropper, ratio } = this.props
    const accepts = acceptance[this.props.accepts] || null
    return (
      <div className="d-flex flex-row filedrop" style={{display: 'flex'}}>
        <div
          className={'filedrop-body ' + (!fileUrl ? 'filedrop-border' : '')}
          style={{marginRight: '5px'}}
        >
          <div className="tb">
            <div
              className={'tbc text-center ' + (!fileUrl ? 'bg-white' : '')}
              style={imgStyle}
            >
              {fileUrl && (
                <React.Fragment>
                  <i className="fal fa-check" />
                  <br />
                  <br />
                  <span className="bg-white p-2 rounded">
                    ... {fileUrl.slice(-10)}
                  </span>
                </React.Fragment>
              )}
              {!fileUrl && <i className="fal fa-upload " />}
            </div>
          </div>
          <input
            type="file"
            required={!fileUrl && required}
            name={name}
            onChange={this.handleFileChange}
            accept={accepts}
            ref={input => (this.inputElement = input)}
          />
        </div>
        <FileDropButtons
          fileUrl={fileUrl}
          withCropper={withCropper}
          toggleModalCropImage={this.toggleModalCropImage}
          handleRemoveFile={this.handleRemoveFile}
          handleUploadClick={_ => {
            this.inputElement.value = ''
            this.inputElement.click()
          }}
        />
        {modalCropImageActive && (
          <ModalCropImage
            ratio={ratio}
            image={image}
            imageSource={fileUrl}
            toggleModalCropImage={this.toggleModalCropImage}
            modalCropImageActive={modalCropImageActive}
            handleSubmitCrop={this.handleSubmitCrop}
          />
        )}
      </div>
    )
  }
}
