import React, { Component } from 'react';
import { buildEmailMjml, mjmlToHtml } from 'services/emailsTemplates';
import { guid, adjustIframeHeight } from 'services/utils';
import { Button, Card, Icon, Modal, Popconfirm } from 'antd';
import Form from 'components/Form';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import './index.css';

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Section extends Component {

  constructor(props, kind) {
    super(props);
    this.kind = kind;
    this.state = {};
    this.iframeRef = React.createRef();
  }

  loadSectionPreview = () =>
    mjmlToHtml(buildEmailMjml([this.asMjml()])).then(r => r.data)

  showConfigModal(bool) {
    this.setState({
      configModalVisible: bool
    });
    this.props.isConfigModalOpen && this.props.isConfigModalOpen(bool);
  }

  renderConfigForm() {
    return null;
  }

  handleConfigSave = (values) => {
    this.props.handleConfigSave(values);
    this.showConfigModal(false);
    this.setState({
      iframeLoaded: false
    });
  }

  render() {
    const { first, last, onUp, onDown, onDelete, attributes, meta } = this.props;
    const { iframeLoaded } = this.state;
    const setIframeLoaded = iframeLoaded => this.setState({ iframeLoaded });

    return (
      <div style={{ marginBottom: "0" }} className="section-block">
        <div className="manage-bar">
          <div className="actions">
            <Button
              type="secondary"
              onClick={() => this.showConfigModal(true)}
              style={{ margin: "5px 5px" }}
            >Modifier</Button>
            {!first && <Button icon="arrow-up" onClick={onUp} />}
            {!last && <Button icon="arrow-down" onClick={onDown} />}
            <Popconfirm onConfirm={onDelete} onCancel={() => {}}
              placement="bottomRight"
              title="Etes-vous sûr ?"
              okText="Oui" cancelText="Non"
            >
              <Button icon="delete" />
            </Popconfirm>
          </div>
        </div>
        <Frame
          ref={this.iframeRef}
          style={{width: '100%'}}
          frameBorder={0}
          contentDidMount={() => setIframeLoaded(true)}
        >
          <FrameContextConsumer>
            {({document, window}) => {
              if (this.iframeRef.current && !iframeLoaded) {
                this.loadSectionPreview().then(html => {
                  document.close()
                  document.write(html)
                  adjustIframeHeight(this.iframeRef.current.node)
                })
              }
            }}
          </FrameContextConsumer>
        </Frame>
        <Form
          value={ attributes }
          onSubmitSuccess={this.handleConfigSave}
        >
          {form => (
            <Modal
              title="Modifier la section"
              visible={this.state.configModalVisible}
              width={700}
              onOk={form.handleSubmit}
              onCancel={() => {
                this.showConfigModal(false);
                form.reset();
              }}
              footer={
                <>
                  <Button
                    onClick={() => this.showConfigModal(false)}>
                    Annuler
                  </Button>
                  <Button 
                    type="primary"
                    onClick={form.handleSubmit}>
                    OK
                  </Button>
                </>
              }
            >
              {attributes && this.renderConfigForm(form, meta)}
            </Modal>
          )}
        </Form>
      </div>
    )
  }
}

export default Section;
