import jwtDecode from 'jwt-decode';
import cookie from 'react-cookies';

export const wrapInPromise = (f) =>
  new Promise((resolve) => resolve(f()));

export const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const adjustIframeHeight = (iframe, extraHeight) => {
  const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
  if (iframeWin.document.body) {
    const calculatedHeight = (iframeWin.document.body.scrollHeight || iframeWin.document.documentElement.scrollHeight) + (extraHeight || 0);
    iframe.height = calculatedHeight;
  }
};

export const decodeTokenClaims = (token) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return {};
  }
};

export const getSlugParam = () => {
  const search = window.location.search
  const match = search && search.match(/slug=([^&]*)/)
  const querySlug = match && decodeURIComponent(match[1]);
  if (querySlug) {
    localStorage.setItem('slug', querySlug);
  }
  return querySlug || localStorage.getItem('slug');
}

export const getSlugQuery = () => `?slug=${getSlugParam() || ''}`;

export const getTokenHeader = () => cookie.load('token') && {
  headers: {
    Authorization: 'Bearer ' + cookie.load('token')
  }
};
