import React from 'react';
import { Icon } from 'antd';
import moment from 'moment';

export const filtersToJSX = (filters) => {
  if (! filters) return '';
  const mapField = f => {
    if (f === 'birthdate') return 'Date de naissance';
    if (f === 'gender') return 'Civilité';
    if (f === 'addressPostalCode') return 'Code postal';
    if (f === 'addressCity') return 'Ville';
    if (f === 'optinEmail') return 'Abonné email';
    if (f === 'optinSms') return 'Abonné SMS';
    if (f === 'text1') return 'Champ texte 1';
    if (f === 'text2') return 'Champ texte 2';
    if (f === 'text3') return 'Champ texte 3';
    if (f === 'text4') return 'Champ texte 4';
    if (f === 'text5') return 'Champ texte 5';
    if (f === 'date1') return 'Champ date 1';
    if (f === 'date2') return 'Champ date 2';
    if (f === 'date3') return 'Champ date 3';
    if (f === 'date4') return 'Champ date 4';
    if (f === 'date5') return 'Champ date 5';
    if (f === 'float1') return 'Champ nombre 1';
    if (f === 'float2') return 'Champ nombre 2';
    if (f === 'float3') return 'Champ nombre 3';
    if (f === 'boolean1') return 'Champ case a cocher 1';
    if (f === 'boolean2') return 'Champ case a cocher 2';
    if (f === 'boolean3') return 'Champ case a cocher 3';
    if (f === 'boolean4') return 'Champ case a cocher 4';
    if (f === 'boolean5') return 'Champ case a cocher 5';
    return f;
  }
  const mapOperator = op => {
    if (op === 'AND') return 'ET';
    if (op === 'OR') return 'OU';
    if (op === 'contains') return 'contient';
    if (op === '<=') return 'inférieur à';
    if (op === '>=') return 'supérieur à';
    if (op === '=') return '=';
    return op;
  }
  const mapParam = p => {
    if (Array.isArray(p)) return p.join(' OU ');
    if (p === true) return 'coché';
    if (p === false) return 'décoché';
    if (p === 'male') return 'Mr';
    if (p === 'female') return 'Madame';
    if (p === 'other') return 'Autre';
    return p;
  }
  const renderNode = ({ operator, children, key, param }) => {
    const isLeaf = !children;
    if (isLeaf)
      return <span><Icon type="caret-right" style={{ fontSize: '0.8rem' }}/> {mapField(key)} {mapOperator(operator)} {mapParam(param)}<br /></span>
    else return <div>{children.map(renderNode)}</div>;
  }
  try {
    return renderNode(filters);
  } catch (e) {
    console.log("error rendering filter", filters);
  }
}

/**
 * Returns an error string message or null if filters are valid
 */
export const validateFilters = (filters) => {
  if (!filters || !filters.children.length) {
    return "merci d'ajouter au moins un filtre";
  }
  if (!filters.children.every(filter =>
    filter.operator && filter.key && (filter.param || filter.param === false)
  )) {
    return "les filtres ajoutés sont invalides";
  }
  return null;
}

const isValidKey = k => [
  'email', 'phone', 'firstname', 'lastname', 'gender', 'birthdate',
  'optinEmail', 'optinSms', 'addressCity', 'addressPostalCode',
  'createdAt', 'updatedAt',
  'text1', 'text2', 'text3', 'text4', 'text5',
  'float1', 'float2', 'float3',
  'date1', 'date2', 'date3', 'date4', 'date5',
  'boolean1', 'boolean2', 'boolean3', 'boolean4', 'boolean5'
].includes(k);

const isDate = k => ['birthdate', 'createdAt', 'updatedAt', 'date1', 'date2', 'date3', 'date4', 'date5'].includes(k);
const isBoolean = k => ['boolean1', 'boolean2', 'boolean3', 'boolean4', 'boolean5'].includes(k);
const dateFormat = 'DD/MM/YYYY';

export const buildWhere = (filters) => {
  if (!filters) return null;
  let where = {$and: []};
  filters.children.forEach(({ operator, key, param }) => {
    if (!isValidKey(key)) return;
    // Special handling for optinEmail and optinSms
    if (key === 'optinEmail') {
      where.$and.push({ $and: [
        { optinEmailAt: (param ? { $ne: null } : null) },
        { optoutEmailAt: null },
      ]});
      return;
    }
    if (key === 'optinSms') {
      where.$and.push({ $and: [
        { optinSmsAt: (param ? { $ne: null } : null) },
        { optoutSmsAt: null },
      ]});
      return;
    }
    if (isDate(key) && param) {
      param = moment(param, dateFormat);
    }
    if (isBoolean(key)) {
      where.$and.push(param ? { [key]: param } : { [key]: { $not: true } });
      return;
    }
    if (operator === '=') {
      where.$and.push({ [key]: param });
    } else if (operator === '<=') {
      where.$and.push({ [key]: { $lte: param }});
    } else if (operator === '>=') {
      where.$and.push({ [key]: { $gte: param }});
    } else if (operator === 'contains') {
      const orClauses = param.map(p => ({ [key]: { $iLike: `%${p}%` } }));
      where.$and.push(orClauses.length === 1 ? orClauses[0] : { $or: orClauses });
    }
  });
  return where;
};
