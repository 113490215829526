import React, { Component, Fragment } from 'react';
import { Button, Icon, Input, Popconfirm, message, Modal, Card } from 'antd';
import BottomBar from '../../../../../components/BottomBar';
import AudienceForm from '../../../AudienceForm';

export default class CampaignEditorAudience extends Component {
  state = {
    emails: [],
    campaign: this.props.data
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    const { data } = this.props;
    this.setState({
      emails: data && data.emails || [],
      isFormTouched: false,
      testEmail: null
    });
  }

  removeEmailField = (i) => {
    this.setState((state, props) => ({
      emails: state.emails.filter((_, eIndex) => eIndex !== i),
      isFormTouched: true
    }));
  }

  addEmailField = () => {
    this.setState((state, props) => ({
      emails: [ ...state.emails, "" ],
      isFormTouched: true
    }));
  }

  handleEmailChange = (e, i) => {
    const nextEmail = e.target.value;
    this.setState((state, props) => ({
      emails: state.emails.map((e, emailIndex) => (emailIndex === i) ? nextEmail : e),
      isFormTouched: true
    }));
  }

  handleSubmit = () => {
    const { campaign } = this.state;
    this.props.onCampaignAudienceSubmit(
      campaign
    ).then(this.setState({
      isFormTouched: false
    }));
  }

  handleTest = () => {
    const { emails } = this.state;
    this.props.onTest(emails);
  }

  handleAudienceChange = (campaign) => {
    this.setState({
      isFormTouched: true,
      campaign
    });
  }

  render() {
    const { data } = this.props;
    const { emails, isFormTouched, testModalVisible, testEmail, targetsCount, credits } = this.state;

    let content;
    if (data.sentAt) {
      content = (
        <Card>
          Cette campagne a ciblé un total de {data.sentContactsCount} contact{data.sentContactsCount > 1 && 's'}
        </Card>
      );
    } else {
      content = (
        <Fragment>
          <AudienceForm
            value={data}
            onChange={this.handleAudienceChange}
            onFetchedTargetsCountAndCredits={(targetsCount, credits) => { this.setState({ targetsCount, credits }) }}
            campaignKind="email" />

          <Modal visible={testModalVisible}
            cancelText='Annuler'
            onOk={() => {
              this.setState({
                testModalVisible: false
              });
              this.props.onTestCampaign([testEmail]);
            }}
            onCancel={() => {
              this.setState({
                testModalVisible: false
              });
            }}>
            <div className="ant-form-item">
              <div className="ant-form-item-label">
                <label>Email sur lequel envoyer le test</label>
              </div>
              <div className="ant-form-item-control">
                <span className="ant-form-item-children">
                  <Input placeholder="me@email.com" value={testEmail} onChange={e => {
                    this.setState({
                      testEmail: e.target.value
                    });
                  }} />
                </span>
              </div>
            </div>
          </Modal>
        </Fragment>
      );
    }
    return (
      <div>
        {content}
        <BottomBar
          left={
            <Button type="primary" icon="left" onClick={() => this.props.onPrevious()}>template</Button>
          }
          right={!data.sentAt && !data.sendAt && (
            <div style={{ display: "inline-block" }}>
              {isFormTouched && (
                <Button type="primary"
                  onClick={this.handleSubmit}
                >
                  enregistrer
                </Button>
              )}
              {!isFormTouched && (
                <Button onClick={() => {
                  this.setState({
                    testModalVisible: true
                  });
                }}>
                  tester
                </Button>
              )}
              {!isFormTouched && (targetsCount == null || targetsCount > credits) && (
                <Button icon="sound" type="primary" size="large" disabled>
                  Envoyer
                </Button>
              )}
              {(!isFormTouched && (targetsCount != null && targetsCount <= credits)) && (
                <Popconfirm title="La campagne va être envoyée aux contacts choisis, êtes-vous sûr ?"
                  onConfirm={this.props.onSendCampaign}
                  onCancel={() => null} okText="Oui" cancelText="Non"
                >
                  {!isFormTouched && (
                    <Button icon="sound" type="primary" size="large">
                      Envoyer
                    </Button>
                  )}
                </Popconfirm>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}
