import React, { Fragment } from 'react';
import Section from '..';
import { Button } from 'antd';
import Form from 'components/Form';

const { Input, RichText } = Form

export const textAsMjml = ({ title, content: { html } = {} }) => ({
  "tagName": "mj-section",
  "attributes": {
    "background-color": "#fff",
    "full-width": "full-width"
  },
  "children": [{
    "tagName": "mj-column",
    "attributes": {
      "width": "100%",
      "vertical-align": "middle"
    },
    "children": [{
      "tagName": "mj-text",
      "attributes": {
        "font-family": "Helvetica Neue",
        "align": "center",
        "font-size": "18px",
        "font-weight": "bold",
        "color": "#45474e",
        "padding-top": "25px",
        "padding-bottom": "10px"
      },
      "content": title
    }, {
      tagName: "mj-divider",
      attributes: {
        "border-color": "#000",
        "border-style": "solid",
        "border-width": "1px",
        "padding-left": "100px",
        "padding-right": "100px",
        "padding-top": "25px",
        "padding-bottom": "25px"
      }
    }, {
      "tagName": "mj-text",
      "attributes": {
        "font-family": "Helvetica Neue",
        "align": "left",
        "font-size": "13px",
        "padding-bottom": "25px",
        "color": "#45474e"
      },
      "content": html || ''
    }]
  }]
});

export default class Text extends Section {
  constructor(props) {
    super(props, "Texte");
  }

  asMjml = () => textAsMjml(this.props.attributes)

  renderConfigForm = (form) => {
    return (
      <Fragment>
        <Input
          path={['title']}
          label="Titre"
          form={form} />
        <RichText
          path={['content']}
          label="Texte"
          form={form} />
      </Fragment>
    );
  }

}
