import React, { Fragment } from 'react';
import { Table, Tag, Icon, Checkbox, Button, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

const columns = (onContactDelete) => [
  {
    title: 'Identité',
    key: 'identity',
    render: (_, contact) => <Fragment>
      {contact.gender === 'male' && 'M. '}
      {contact.gender === 'female' && 'Mme '}
      {(contact.lastname || '') + ' ' + (contact.firstname || '')}
      <br />
      {contact.email && (<span><Icon type='mail' /> {contact.email}<br /></span>)}
      {contact.phone && (<span><Icon type="phone" /> {contact.phone}<br /></span>)}
      {contact.birthdate && <span> Né le {moment(contact.birthdate).format(dateFormat)}</span>}
    </Fragment>
  }, {
    title: "Adresse",
    key: 'address',
    render: (_, contact) => <Fragment>
      {contact.addressLine1 && <span>{contact.addressLine1}<br /></span>}
      {contact.addressLine2 && <span>{contact.addressLine2}<br /></span>}
      {contact.addressPostalCode && <span>{contact.addressPostalCode}<br /></span>}
      {contact.addressCity && <span>{contact.addressCity}<br /></span>}
    </Fragment>
  }, {
    title: "Souscriptions",
    key: 'subscriptions',
    render: (_, contact) => <Fragment>
      <Checkbox checked={!!contact.optinEmailAt}>Email</Checkbox>
      <br />
      <Checkbox checked={!!contact.optinSmsAt}>SMS</Checkbox>
    </Fragment>
  }, {
    key: 'timestamps',
    render: (_, contact) => <Fragment>
      {contact.createdAt && <span><Tag color="cyan">créé {moment(contact.createdAt).fromNow()}</Tag><br /></span>}
      {contact.updatedAt && <span><Tag color="cyan">mis à jour {moment(contact.updated).fromNow()}</Tag><br /></span>}
      {contact.optoutEmailAt && <span><Tag color="volcano">désabonné email depuis {moment(contact.optoutEmailAt).fromNow()}</Tag><br /></span>}
      {contact.optoutSmsAt && <span><Tag color="volcano">désabonné SMS depuis {moment(contact.optoutSmsAt).fromNow()}</Tag><br /></span>}
      {!contact.optoutEmailAt && contact.optinEmailAt && <span><Tag color="green">abonné email depuis {moment(contact.optinEmailAt).fromNow()}</Tag><br /></span>}
      {!contact.optoutSmsAt && contact.optinSmsAt && <span><Tag color="green">abonné SMS depuis {moment(contact.optinSmsAt).fromNow()}</Tag></span>}
    </Fragment>
  }, {
    key: 'actions',
    render: (_, contact) => (
      <span>
        <Link to={`/audience/contacts/edit/${contact.id}`}><Button icon="edit" style={{ marginRight: '5px' }} /></Link>
        <Popconfirm title="Etes-vous sûr de vouloir supprimer ?"
          onConfirm={() => { onContactDelete(contact) }}
          onCancel={() => {}} okText="oui" cancelText="non"
        >
          <Button icon="delete" />
        </Popconfirm>
      </span>
    ),
    style: { textAlign: 'right' },
    width: 100
  }
];

export default ({ loading, contacts, onContactDelete, rowSelection }) => (
  <Table
    size="middle"
    dataSource={contacts}
    rowKey="id"
    rowSelection={rowSelection}
    columns={columns(onContactDelete)}
    loading={loading}
    locale={{ emptyText: "Pas de données" }}
    pagination={false} />
)
