import { getSlugQuery, getTokenHeader } from './utils';
import ApiStore from './apiStore';
import moment from 'moment';

class Contacts extends ApiStore {

  constructor() {
    super('contacts');
  }

  importFromKobaSites = (contacts) =>
    this.http.post(`${this.basePath}/importFromKoba${getSlugQuery()}`, this.formatContacts(contacts), getTokenHeader());

  bulkDelete = (contactIds) => {
    return this.http.patch(`${this.basePath}/bulkDelete${getSlugQuery()}`, contactIds, getTokenHeader());
  }

  // private functions
  //
  // Format JSON payload to match campaigns-api database
  formatContacts = (contacts) => contacts.map(({ personal_information:
    { id, zipcode, address, city, country, sms_newsletter, newsletter, ...rest }}) =>
    ({
      ...(sms_newsletter && { optinSmsAt: moment() }),
      ...(newsletter && { optinEmailAt: moment() }),
      addressPostalCode: zipcode,
      addressLine1: address,
      addressLine2: country,
      addressCity: city,
      ...rest
    })
  )
}

export default Contacts;
