import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputNumber as AntdInputNumber } from 'antd'

export default class InputNumber extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { label, path, form, style, className } = this.props
    const value = form.getValue(path)
    const handleChange = v => form.handleChange(path, v)
    const error = form.getError(path)
    const handleTouched = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className="ant-form-item-label">
          <label>{label}</label>
        </div>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <AntdInputNumber value={value} onChange={handleChange} onBlur={handleTouched} onKeyUp={e => e.key === 'Enter' && handleTouched()} />
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
        </div>
      </div>
    )
  }
}
