import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input as AntdInput } from 'antd'
import { ChromePicker } from 'react-color'

export default class ColorPicker extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { label, path, form, style, className } = this.props
    const value = form.getValue(path)
    const handleChange = color => form.handleChange(path, color.hex)
    const error = form.getError(path)
    const handleTouched = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className="ant-form-item-label">
          <label>{label}</label>
        </div>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <ChromePicker color={value} onChangeComplete={handleChange} disableAlpha={true} style={{margin: "auto"}} />
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
        </div>
      </div>
    )
  }
}
