import React from 'react';
import { Spin } from 'antd';
import { getClientByHostname } from 'services/client';

const PublicAppContext = React.createContext();

export default class PublicApp extends React.Component {
  state = {
    data: {},
    loading: true
  }

  static Consumer = (props) =>
      <PublicAppContext.Consumer {...props}>
        {context => {
          if (!context) {
            throw new Error(
                `PublicApp.Consumer cannot be rendered outside the PublicApp component`,
            )
          }
          return props.children(context)
        }}
      </PublicAppContext.Consumer>

  componentDidMount() {
    getClientByHostname(window.location.hostname)
        .then(config => {
          this.setState({
            loading: false,
            data: config.client
          })
          localStorage.setItem('slug', config.client.slug)
        })
        .catch(_ =>
            this.setState({
              loading: false
            })
        )
  }

  render() {
    const { children } = this.props;
    if (this.state.loading) return <Spin size="large" />;

    return (
        <PublicAppContext.Provider value={{...this.state}}>
          {children}
        </PublicAppContext.Provider>
    )
  }
}
