import axios from 'axios';
import { getSlugQuery, getTokenHeader } from './utils';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000";
const http = axios.create({
  baseURL,
});
http.interceptors.response.use((response) => response.data);

export const getClient = () =>
  http.get(`/client${getSlugQuery()}`, getTokenHeader())

export const getClientByHostname = (hostname) =>
  http.get(`/client/landing?hostname=${hostname}`)
