import { guid } from './utils.js';
import ApiStore from './apiStore';
import { getSlugQuery, getTokenHeader } from './utils';
import axios from 'axios';

export default class Users extends ApiStore {

  constructor() {
    super('users');
  }

  me = () =>
    this.http.get(`me${getSlugQuery()}`, getTokenHeader());

  sites = () =>
    this.http.get(`user/sites${getSlugQuery()}`, getTokenHeader());

  siteContents = (siteSlug, contentKind, contentFilter = '', contentFilterSinceDate = '') =>
    this.http.get(`user/site/contents${getSlugQuery()}&siteSlug=${siteSlug}&contentKind=${contentKind}&contentFilter=${contentFilter}&contentFilterSinceDate=${contentFilterSinceDate}`, getTokenHeader());

}

export const inviteUser = (slug, email) => {
  const inviteUrl = `${process.env.REACT_APP_AUTH_API_URL}/invitations`;
  return axios.post(inviteUrl, {
    invitation: {
      email
    },
    instance: {
      kind: 'campaign',
      slug
    }
  }, getTokenHeader());
}
