import React from 'react';
import PublicApp from 'providers/PublicApp'
import Header from 'templates/PublicAppTemplate/components/Header';
import clearLogo from 'templates/PublicAppTemplate/assets/img/clear-24px.svg';
import 'templates/PublicAppTemplate/assets/css/index.css'

export default ({ history }) => {
    const goBack = () => history.push('/');

    return (
        <PublicApp.Consumer>
            {({ data: { landingPageColor, landingPageBackgroundImageUrl }}) => (
                <>
                    <style>
                        {
                            landingPageColor ?
                                `.landing-container:before { 
                                background: ${landingPageColor}; 
                                opacity: .8; 
                            } 
                            form button.btn-submit { 
                                background: #444; 
                            }` : ''
                        }
                        {    landingPageBackgroundImageUrl ?
                            `.landing-container {
                             background: transparent url('${landingPageBackgroundImageUrl}') center center / cover no-repeat padding-box fixed;
                             }` : ''}
                        }
                    </style>
                    <div className="landing-container flex-direction-row">
                        <Header className="flex-full-width" />

                        <main>
                            <div className="feedback">
                                <div className="clear-container">
                                    <img src={clearLogo} alt="Croix" className="clear" height="24" onClick={goBack} />
                                </div>

                                <p className="feedback-headline">
                                    <span className="greetings">La Mairie vous remercie !</span>
                                    Votre inscription a bien été <br /> prise en compte.
                                </p>

                                <p className="feedback-text">
                                    Vous recevrez prochainement toutes les actualités de votre commune.
                                </p>

                                <button className="accept" onClick={goBack}>OK</button>
                            </div>
                        </main>
                    </div>
                </>
            )}
        </PublicApp.Consumer>
    )
}
