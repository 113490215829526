import React, { Component } from 'react';
import { Layout, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import ApiTable from 'components/ApiTable';

import SegmentsService from 'services/segments';
import { filtersToJSX } from 'services/filters';

const columns = [
  {
    title: 'Nom',
    dataIndex: 'name',
    key: 'name'
  }, {
    title: "Critères",
    dataIndex: 'filters',
    key: 'filters',
    render: v => filtersToJSX(v)
  }
];

const service = new SegmentsService();

export default class SegmentsList extends Component {

  render() {
    return (
      <Layout>
        <Alert type="info" description={<>
          Les filtres sont une configuration de critères de sélection pour vos contacts.
          Il vous est possible de construire et assembler des critères sur les différents champs et intérêts de vos contacts
          et de les combiner entre eux au moment de la configuration de l'audience de vos campagnes.
          <br />
          Ces filtres sont appliqués dynamiquement lors de l'envoi effectif des campagnes.
          </>} className="mb-4" showIcon />
        <div className="Content-header">
          <div className="Content-header-actions">
            <Link to={{ pathname: "/audience/segments/new", search: window.location.search }}>
              <Button>Nouveau filtre</Button>
            </Link>
          </div>
          <h1>Filtres</h1>
        </div>
        <ApiTable
          service={service}
          basePath="/audience/segments"
          deleteSuccessText="Le filtre a bien été supprimé"
          searchKeys={['name']}
          columns={columns}
        />
      </Layout>
    );
  }
}
