import React, { Component } from 'react';
import { Modal, Row, Col, Button, Statistic, Spin } from 'antd';
import CampaignsEmailService from '../../../../services/campaignsEmail';
import './index.css';

const service = new CampaignsEmailService();

export default class StatisticsModal extends Component {
  state = {
    statistics: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({statistics: null});
      service.getCampaignStatistics(this.props.campaignId).then(res => {
        this.setState({statistics: res.data});
      });
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const { statistics } = this.state;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={
          <Button type="primary" onClick={onCancel}>OK</Button>
        }
      >
        {!statistics && (
          <div className="StatisticsModal-centered">
            <Spin />
          </div>
        )}
        {statistics && (
          <Row>
          <Col span={12}><Statistic title="Envoyés" value={statistics.emailsSent} /></Col>
          <Col span={12}><Statistic title="Ouvertures" value={statistics.uniqueOpens} /></Col>
          <Col span={12}><Statistic title="Clics" value={statistics.uniqueClicks} /></Col>
          <Col span={12}><Statistic title="Reportés comme spam" value={statistics.abuseReports} /></Col>
          <Col span={12}><Statistic title="Désinscriptions" value={statistics.unsubscribed} /></Col>
          </Row>
        )}
      </Modal>
    );
  }
}
