import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, Steps, Button, Layout, Alert, Icon, message } from 'antd';
import CampaignsSmsService from 'services/campaignsSms';
import ConfigService from 'services/config';
import CampaignEditorSettings from './Settings';
import CampaignEditorAudience from './Audience';
import StatisticsModal from '../StatisticsModal';
import moment from 'moment';
import 'moment/locale/fr';

const Step = Steps.Step;
const Content = Layout.Content;

const configService = new ConfigService();

class CampaignEditor extends Component {
  state = {
    currentStep: 0
  };
  campaignsSmsService = new CampaignsSmsService();

  componentDidMount() {
    const { match } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const step = parseInt(urlParams.get('step') || "0");
    if (match.params.id) {
      this.campaignsSmsService.get(match.params.id, { params: { include: [{ resource: 'Segment' }] } }).then(campaign => {
        this.setState({
          campaign,
          currentStep: step
        })
      })
    } else {
      this.setState({
        campaign: {}
      });
    }
  }

  changeStep = (inc) => {
    this.setState((state, props) => ({
      currentStep: state.currentStep + inc
    }));
  }

  handleCampaignSettingsSubmit = (values) => {
    const { match } = this.props;
    const { campaign } = this.state;
    if (match.params.id) {
      this.campaignsSmsService.replace(match.params.id, {
        ...(campaign || {}),
        ...values
      }).then(_ =>
        this.campaignsSmsService.get(match.params.id, { params: { include: [{ resource: 'Segment' }] } })
      ).then(campaign => {
        this.setState((state, props) => ({
          campaign: { ...campaign, Segments: state.campaign.Segments },
          currentStep: state.currentStep + 1
        }));
      });
    } else {
      this.campaignsSmsService.create(
        values
      ).then(campaign => {
        this.props.history.push('/campaigns/sms/edit/' + campaign.id + '?step=1');
      });
    }
  }

  handleCampaignAudienceSubmit = (values) => {
    const { match, history } = this.props;
    const { campaign } = this.state;
    return this.campaignsSmsService.replace(match.params.id, {
      ...(campaign || {}),
      ...values
    }).then(_ =>
      this.campaignsSmsService.get(match.params.id, { params: { include: [{ resource: 'Segment' }] } })
    ).then(campaign => {
      this.setState({
        campaign: { ...campaign, Segments: values.Segments },
      });
      message.success("votre campagne a bien été enregistrée", 5);
    });
  }

  testCampaign = (phones) => {
    const { campaign } = this.state;
    this.campaignsSmsService.test(campaign.id, phones)
      .then(_ => message.success("Votre test a bien été envoyé", 5))
      .catch(({response: {data = {}}}) => message.error('Une erreur est survenue : ' + data.details))
  }

  sendCampaign = async () => {
    try {
      const { history } = this.props;
      const { campaign } = this.state;
      const config = await configService.getConfig();
      if (!config.company || !config.addressLine1 || !config.addressCity || !config.addressPostalCode) {
        message.error(
          <span>
            Merci de saisir vos informations de contact ! <a href="/config">Aller sur ma page de configuration</a>
          </span>
        );
      } else {
        this.campaignsSmsService.send(campaign.id)
          .then(res => {
            message.success(`Votre campagne a bien été envoyée à une base de ${res.data ? res.data.sentContactsCount : 0} contacts`, 5);
            history.push('/campaigns/sms');
          })
          .catch(({response: {data = {}}}) => message.error('Une erreur est survenue : ' + data.details))
      }
    } catch(err){
      message.error('Une erreur est survenue, veuillez contacter le service client');
    }
  }

  render() {
    const { match } = this.props;
    const { currentStep, campaign, templates } = this.state;
    if (!campaign) return null;

    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <Link to="/campaigns/sms">
              <Button>voir toutes mes campagnes SMS</Button>
            </Link>
          </div>
          <h1><Icon className="mr-1" type="edit" />{(campaign && campaign.name) || 'Nouvelle campagne SMS'}</h1>
        </div>
        <Steps className="mt-2" current={ currentStep } style={{ padding: '0 2rem', width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
          <Step title="Configuration" />
          <Step title="Audience" />
        </Steps>
        <div className="mt-2">
          {campaign.sentAt && (
            <Alert
              className="mt-4 mb-2"
              type="success"
              message={
                <Fragment>
                  Cette campagne a été envoyée {moment(campaign.sentAt).fromNow()}
                  <StatisticsModal
                    visible={this.state.statisticsModalVisible}
                    onCancel={() => this.setState({ statisticsModalVisible: false })}
                    campaignId={campaign.id} />
                  <Button style={{float: 'right'}} icon="area-chart" size="small" onClick={() => this.setState({ statisticsModalVisible: true })}>voir les statistiques</Button>
                </Fragment>
              }
              showIcon />
          )}
          {/* the key prop is there to force component reload each time the route change */}
          {(currentStep === 0) &&
            <CampaignEditorSettings
              key={campaign ? campaign.id : "new"}
              data={campaign}
              onCampaignSettingsSubmit={this.handleCampaignSettingsSubmit}
              onNext={() => this.changeStep(1)} />}
          {(currentStep === 1) &&
            <CampaignEditorAudience
              onCampaignAudienceSubmit={this.handleCampaignAudienceSubmit}
              data={campaign}
              onPrevious={() => this.changeStep(-1)}
              onTestCampaign={this.testCampaign}
              onSendCampaign={this.sendCampaign} />}
        </div>
      </Layout>
    );
  }
}

export default CampaignEditor;
