import ApiStore from './apiStore';
import axios from 'axios';
const { getSlugQuery, getTokenHeader } = require('./utils');

class CampaignsSms extends ApiStore {

  constructor() {
    super('/campaigns/sms');
  }

  send(id) {
    return axios.post(`${this.baseURL}/campaigns/sms/${id}/send${getSlugQuery()}`, {}, {
      ...getTokenHeader()
    });
  }

  test(id, phones) {
    return axios.post(`${this.baseURL}/campaigns/sms/${id}/test${getSlugQuery()}`, {
      phones
    }, {
      ...getTokenHeader()
    })
  }

  getCampaignStatistics(id) {
    return axios.get(`${this.baseURL}/campaigns/sms/${id}/statistics${getSlugQuery()}`, {
      ...getTokenHeader()
    });
  }

  duplicate = (doc) => {
    const {
      createdAt,
      id,
      sendAt,
      sentAt,
      sentContactsCount,
      statistics,
      updatedAt,
      shouldIntersectSegments,
      smsFactorCampaignId,
      ...duplicateCampaignSms } = doc ;

    duplicateCampaignSms.name = duplicateCampaignSms.name + ' - Copie';
    return this.create(duplicateCampaignSms);
  }

}

export default CampaignsSms;
