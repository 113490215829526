import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';


export default class RichText extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const {path, form} = this.props;
    let editorState;
    const value = form.getValue(path);
    if (value && value.draftRaw) {
      editorState = EditorState.createWithContent(convertFromRaw(value.draftRaw));
    } else if (value && value.html) {
      editorState = EditorState.createWithContent(convertFromHTML(value.html));
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {editorState};
  }

  handleChange = editorState => {
    this.setState({editorState});
    const contentState = this.state.editorState.getCurrentContent();
    const draftRaw = convertToRaw(contentState);
    const html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    const fieldValue = {html, draftRaw};
    this.props.form.handleChange(this.props.path, fieldValue)
  };


  render() {
    const {label, path, form, style, className, explain} = this.props;
    const { editorState } = this.state;
    const error = form.getError(path);
    const handleTouched = () => form.handleTouched(path);

    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className="ant-form-item-label">
          <label>{label}</label>
        </div>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children ">
            <Editor
                toolbar={toolbarOptions}
                editorState={editorState}
                wrapperClassName="editor-toolbar"
                editorClassName="editor"
                onEditorStateChange={this.handleChange}
                onBlur={handleTouched}
            />
          </span>
          {error && (
              <div className="ant-form-explain">{error}</div>
          )}
          {!error && explain && (
              <div className="ant-form-explain">{explain}</div>
          )}
        </div>
      </div>
    )
  }
}

const toolbarOptions = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
  },
  emoji: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },

  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],

  },
};
