import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox as AntdCheckbox } from 'antd'

export default class Checkbox extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { label, path, form, style, className } = this.props
    const value = form.getValue(path)
    const handleChange = e => form.handleChange(path, e.target.checked)
    const error = form.getError(path)
    const handleBlur = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <AntdCheckbox checked={value} onChange={handleChange} onBlur={handleBlur}>
              {label}
            </AntdCheckbox>
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
        </div>
      </div>
    )
  }
}
