import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select as AntdSelect } from 'antd'

export default class Select extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    /** Ex: [{value: "male", label: "Homme"}, {value: "female", label: "Femme"}] */
    options: PropTypes.array,
    mode: PropTypes.oneOf(['single', 'multiple']),
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { label, path, form, options, style, className, mode } = this.props
    const value = form.getValue(path)
    const handleChange = v => form.handleChange(path, v)
    const error = form.getError(path)
    const handleBlur = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        <div className="ant-form-item-label">
          <label>{label}</label>
        </div>
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <AntdSelect value={value} onChange={handleChange} size="large"
              mode={mode} style={{width: "100%"}} allowClear={true} onBlur={handleBlur}
            >
              {options.map(({value, label}) =>
                <AntdSelect.Option key={value} value={value}>{label}</AntdSelect.Option>
              )}
            </AntdSelect>
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
        </div>
      </div>
    )
  }
}
