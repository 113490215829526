import { Map, Set, getIn, fromJS } from 'immutable';

// All predicates should return either a strict true datatype or a error string

export const required = o => (o != null && o !== '') || 'le champ est requis';

export const isEmail = o =>
  o == null ||
  o === '' ||
  (o.includes('@') && o.split('@')[1].includes('.')) ||
  "l'email est invalide";

export const isPhone = o =>
  o == null ||
  o === '' ||
  RegExp('^[0-9 \-+]*$').test(o) ||
  "le téléphone est invalide"

export const alphaNum = o =>
  o == null ||
  o === '' ||
  RegExp("^[a-zA-Z0-9 ]*$").test(o) ||
  "lettres et chiffres uniquement";

export const isIn = values => o =>
  o == null ||
  o === '' ||
  values.includes(o) ||
  `merci de choisir une valeur parmi ${values.join(', ')}`;

export const minLength = min => o =>
  o == null ||
  o === '' ||
  o.length >= min ||
  `merci de saisir au moins ${min} caractères`;

export const maxLength = max => o =>
  o == null ||
  o === '' ||
  o.length <= max ||
  `le champ est limité à ${max} caractères maximum`;

export const allowedKeys = keys => o => {
  if (o == null) return true;
  const extraKeys = Set(Object.keys(o)).subtract(keys)
  return extraKeys.size === 0 ||
    `les champs suivant ne sont pas autorisés : ${extraKeys.toArray().map(k => `"${k}"`)}`
}

const combineValidators = ([v1, v2, ...vs]) => {
  if (!v1) return _ => true;
  if (!v2) return v1;
  const v = o => {
    const r1 = v1(o);
    return r1 !== true ? r1 : v2(o);
  };
  return combineValidators([v, ...vs]);
};

/*
 * Utility function to run some validations against a data object.
 * Errors are collected and returned associated with the value path
 *
 * Ex:
 * import { validate, required, minLength } from 'services/Validation';
 *
 * const validations = [
 *   {
 *     path: ['name'],
 *     validators: [required, minLength(3)]
 *   },
 *   {
 *     path: ['questions', [], 'title'],
 *     validators: [required, minLength(3)]
 *   },
 *   {
 *     path: ['questions', [], 'choices', [], 'label'],
 *     validators: [required, minLength(3)]
 *   }
 * ];
 *
 * validate({name: null, questions: [{title: "t", choices: [{label: "l"}]}]}, validations)
 * => [
 *      [["name"], "le champ est requis"],
 *      [["questions", 0, "title"], "merci de saisir au moins trois caractères"],
 *      [["questions", 0, "choices", 0, "label"], "merci de saisir au moins trois caractères"],
 *    ]
 * You can use the asImmutable helper below to get an Immutable.Map instead
 */
export const validate = (obj, validations, prefix = []) => {
  let [objValidations, recurValidations] = [[], []];
  validations.forEach(({ path, validators }) => {
    if (path.find(el => Array.isArray(el) && el.length === 0)) {
      recurValidations.push({ path, validators });
    } else {
      objValidations.push({ path, validators });
    }
  });
  let errs = [];
  objValidations.forEach(({ path, validators }) => {
    const err = combineValidators(validators)(getIn(obj, path));
    if (err !== true) {
      errs = [...errs, [prefix.concat(path), err]];
    }
  });
  recurValidations.forEach(({ path, validators }) => {
    const arrIndex = path.findIndex(el => Array.isArray(el) && el.length === 0);
    const pathBeforeArr = path.slice(0, arrIndex);
    const pathAfterArr = path.slice(arrIndex + 1);
    const arr = getIn(obj, pathBeforeArr);
    arr.forEach((el, i) => {
      const arrErrs = validate(
        el,
        [{ path: pathAfterArr, validators }],
        [...prefix, ...pathBeforeArr, i]
      );
      if (arrErrs.length > 0) {
        errs = [...errs, ...arrErrs];
      }
    });
  });
  return errs;
};

export const asImmutable = errors =>
  Map(errors.map(([path, err]) => [fromJS(path), err]));
