import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import BottomBar from '../../../../components/BottomBar';
import { Layout, Row, Button, Icon, Spin, message } from 'antd';
import SegmentsService from 'services/segments';
import { validateFilters } from 'services/filters';
import FiltersBuilder from '../../../../components/FiltersBuilder';
import Form from '../../../../components/Form';
import { required } from '../../../../services/validation';

const { Input } = Form;

export default class SegmentEditor extends Component {
  state = {
    segment: null,
    loading: true
  }
  segmentsService = new SegmentsService();

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.segmentsService.get(match.params.id).then(segment => {
        this.setState({ segment, loading: false });
      });
    } else {
      this.setState({
        segment: {},
        loading: false
      });
    }
  }

  handleFiltersChange = (filters) => {
    this.setState(state => ({
      segment: {
        ...state.segment,
        filters
      }
    }));
  }

  handleSubmit = ({ name }) => {
    this.setState({ saving: true });
    const { history, match } = this.props;
    const { segment: { filters } } = this.state;
    const filtersError = validateFilters(filters);
    if (filtersError) {
      message.error(filtersError);
      return;
    }
    let promise;
    if (match.params.id) {
       promise = this.segmentsService.replace(match.params.id, { name, filters });
    } else {
      promise = this.segmentsService.create({ name, filters });
    }
    promise.then(c => {
      this.setState({ saving: false });
      message.success("le filtre a bien été enregistrée");
      history.push('/audience/segments');
    });
  }

  handleNameChange = (v) => {
    this.setState(state => ({
      segment: {
        ...state.segment,
        name: v
      }
    }));
  }

  render() {
    const { loading, saving, ...state } = this.state;
    const segment = state.segment || {};
    const { match } = this.props;
    const defaultFilters = {
      operator: 'AND',
      children: []
    }
    const { filters = defaultFilters } = segment;
    return (
      <Layout>
        <div className="Content-header">
          <div className="Content-header-actions">
            <Link to="/audience/segments">
              <Button>voir tous mes filtres</Button>
            </Link>
          </div>
          <h1><Icon className="mr-1" type="edit" />{(loading && '...') || (match.params.id && segment.name) || 'Nouveau filtre'}</h1>
        </div>

        {loading && (
          <div style={{width: "100%", textAlign: "center", marginTop: "2rem"}}>
            <Spin></Spin>
          </div>
        )}

        {!loading && (
          <Form
            value={segment}
            onSubmitSuccess={this.handleSubmit}
            rules={[
              {path: ['name'], validators: [required]}
            ]}
          >
            {form =>
              <div className="mt-2">
                <Input
                  path={['name']}
                  label="Nom *"
                  form={form} />
                <div className="ant-form-item">
                  <div className="ant-form-item-label">
                    <label><strong>Construisez votre filtre en sélectionnant un ou plusieurs critères de filtrage sur les contacts</strong></label>
                  </div>
                  <Row>
                    <FiltersBuilder value={filters} onChange={this.handleFiltersChange} />
                  </Row>
                </div>
                <BottomBar
                  right={
                    <div style={{ display: "inline-block" }}>
                      <Button type="primary" onClick={() => {
                        form.handleSubmit();
                      }}>
                        Enregistrer
                      </Button>
                    </div>
                  }
                />
              </div>
            }
          </Form>
        )}

      </Layout>
    )
  }
}
