import React, { Fragment, Component } from 'react';
import { Collapse, Icon, Button, Modal, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import FiltersBuilder from 'components/FiltersBuilder';
import { validateFilters } from 'services/filters';
import Segments from 'services/segments';

const Panel = Collapse.Panel;
const segmentsService = new Segments();

const defaultFilters = {
  operator: 'AND',
  children: [
    { operator: '=', key: null, param: null }
  ]
};

export default class Filter extends Component {
  state = {
    filters: defaultFilters,
    showSaveFiltersModal: false,
    saveFiltersName: '',
  }

  handleFiltersChange = filters => {
    this.setState({
      filters
    });
  }

  handleFiltersClick = () => {
    const { onFiltersSearch } = this.props;
    const { filters } = this.state;
    const filtersError = validateFilters(filters);
    if (filtersError) {
      message.error(filtersError);
      return;
    }
    onFiltersSearch(filters);
  }

  handleFiltersReset = () => {
    const { onFiltersReset } = this.props;
    this.setState({
      filters: defaultFilters
    });
    onFiltersReset(null);
  }

  openFiltersSaveModal = () => {
    const { filters } = this.state;
    const filtersError = validateFilters(filters);
    if (filtersError) {
      message.error(filtersError);
      return;
    }
    this.setState({ showSaveFiltersModal: true });
  }

  handleFiltersSave = () => {
    const { saveFiltersName, filters } = this.state;
    if (saveFiltersName.trim().length >= 3) {
      segmentsService.create({
        name: saveFiltersName,
        filters
      }).then(() => {
        message.success(
          <span>le filtre a bien été enregistré. <a href="/audience/segments">Voir tous mes filtres</a></span>
        );
        this.setState({
          saveFiltersName: '',
          showSaveFiltersModal: false
        });
      })
    } else {
      message.error('merci de saisir au moins trois caractères');
    }
  }

  render() {
    const { filters, showSaveFiltersModal, saveFiltersName } = this.state;
    return (
      <Fragment>
        <Collapse className={this.props.className}>
          <Panel header="Filtrer les contacts" key="1" extra={<Icon type="filter" />}>
            <FiltersBuilder onChange={this.handleFiltersChange} value={filters}></FiltersBuilder>
            <div className="mt-2">
              <Button type="primary" onClick={this.handleFiltersClick} className="mr-2">Filtrer</Button>
              <Button type="danger" onClick={this.handleFiltersReset} className="mr-2">Réinitialiser</Button>
              <Button onClick={this.openFiltersSaveModal}>Enregistrer en tant que filtre d'audience</Button>
            </div>
          </Panel>
        </Collapse>
        <Modal
          visible={showSaveFiltersModal}
          onOk={this.handleFiltersSave}
          onCancel={() => this.setState({ saveFiltersName: '', showSaveFiltersModal: false })}
        >
          <label>Nom du filtre</label>
          <Input
            value={saveFiltersName}
            onChange={e => this.setState({ saveFiltersName: e.target.value })}
          />
        </Modal>
      </Fragment>
    );
  }
}
