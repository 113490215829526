import React, { Component } from 'react';
import { Drawer, Button, Spin, Icon } from 'antd';
import { mjmlToHtml } from '../../../../../../services/emailsTemplates.js';
import { adjustIframeHeight } from '../../../../../../services/utils.js';
import Frame, { FrameContextConsumer } from 'react-frame-component';

const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class PreviewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    }
    this.iframeRef = React.createRef();
  }

  showModal = (bool) => {
    this.setState({
      modalVisible: bool
    });
  }

  loadPreviewHtml = () =>
    mjmlToHtml(this.props.mjml).then(r => r.data);

  render() {
    const { iframeLoaded } = this.state;
    const setIframeLoaded = (iframeLoaded) => this.setState({ iframeLoaded });
    return (
      <div style={{display: "inline-block"}}>
        {/* style={{ top: 0 }} */}
        {/*footer={[
          <Button key="back" type="primary" onClick={() => this.showModal(false)}>Fermer</Button>,
        ]}
        onCancel={() => this.showModal(false)}
        width={900}*/}
        <Drawer
          title="Aperçu"
          visible={this.state.modalVisible}
          destroyOnClose={true}
          placement="right"
          width="70%"
          onClose={() => this.showModal(false)}
        >
          <Frame
            ref={this.iframeRef}
            style={{width: '100%'}}
            frameBorder={0}
          >
            <FrameContextConsumer>
              {
                ({document, window}) => {
                  if (this.iframeRef.current && !iframeLoaded) {
                    this.loadPreviewHtml().then(html => {
                      document.close();
                      document.write(html);
                      if (this.iframeRef.current) {
                        adjustIframeHeight(this.iframeRef.current.node);
                      }
                    });
                  }
                }
              }
            </FrameContextConsumer>
          </Frame>
        </Drawer>
        <Button icon="eye" onClick={() => this.showModal(true)}>Prévisualiser</Button>
      </div>
    )
  }
}

export default PreviewButton;
