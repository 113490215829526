import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input as AntdInput } from 'antd'

export default class Input extends Component {
  static propTypes = {
    label: PropTypes.string,
    path: PropTypes.array.isRequired,
    form: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool
  }

  render() {
    const { label, path, form, style, className, disabled, explain, placeholder } = this.props
    const value = form.getValue(path)
    const handleChange = e => form.handleChange(path, e.target.value)
    const error = form.getError(path)
    const handleTouched = _ => form.handleTouched(path)
    return (
      <div className={"ant-form-item " + (className || '')} style={style}>
        {label && (
          <div className="ant-form-item-label">
            <label>{label}</label>
          </div>
        )}
        <div className={'ant-form-item-control ' + (error ? 'has-error' : '')}>
          <span className="ant-form-item-children">
            <AntdInput
              value={value}
              onChange={handleChange}
              onBlur={handleTouched}
              onKeyUp={e => e.key === 'Enter' && handleTouched()}
              placeholder={placeholder}
              disabled={disabled} />
          </span>
          {error && (
            <div className="ant-form-explain">{error}</div>
          )}
          {!error && explain && (
            <div class="ant-form-explain">{explain}</div>
          )}
        </div>
      </div>
    )
  }
}
