import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Steps, Button, Layout, Icon, Card, Alert, message } from 'antd';
import EmailsTemplatesService from 'services/emailsTemplates';
import CampaignsEmailService from 'services/campaignsEmail';
import ConfigService from 'services/config';
import CampaignEditorSettings from './Settings';
import CampaignEditorAudience from './Audience';
import CampaignEditorTemplate from './Template';
import StatisticsModal from '../StatisticsModal';
import moment from 'moment';

const Step = Steps.Step;
const Content = Layout.Content;

const configService = new ConfigService();
const campaignsEmailService = new CampaignsEmailService();
const emailsTemplateService = new EmailsTemplatesService();

class CampaignEditor extends Component {
  state = {
    currentStep: 0,
    campaign: {}
  }

  componentDidMount() {
    const { match } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const currentStep = parseInt(urlParams.get('step') || "0");
    if (match.params.id) {
      campaignsEmailService.get(match.params.id, { params: { include: [{ resource: 'Segment' }] } }).then(campaign => {
        this.setState({
          campaign,
          currentStep
        })
      })
    } else {
      configService.getConfig().then(({ from }) =>
        this.setState(({ campaign }) => ({
          campaign: {
            ...campaign,
            from
          }
        }))
      )
    }

    emailsTemplateService.get().then(resp => {
      this.setState({
        templates: resp.rows
      })
    })
  }

  changeStep = (inc) => {
    this.setState((state, props) => ({
      currentStep: state.currentStep + inc
    }));
  }

  handleCampaignSettingsSubmit = (values) => {
    const { match } = this.props;
    const { campaign } = this.state;
    if (match.params.id) {
      campaignsEmailService.replace(match.params.id, {
        ...(campaign || {}),
        ...values
      }).then(campaign => {
        this.setState((state, props) => ({
          campaign: { ...campaign, Segments: state.campaign.Segments },
          currentStep: state.currentStep + 1
        }));
      });
    } else {
      campaignsEmailService.create({
        ...values,
        kind: "email"
      }).then(campaign => {
        this.props.history.push('/campaigns/email/edit/' + campaign.id + '?step=1');
      });
    }
  }

  handleCampaignTemplateSubmit = (values, stepInc = 1) => {
    const { match, history } = this.props;
    const { campaign } = this.state;
    campaignsEmailService.replace(match.params.id, {
      ...(campaign || {}),
      template: values
    }).then(campaign => {
      this.setState((state, props) => ({
        campaign: { ...campaign, Segments: state.campaign.Segments },
        currentStep: state.currentStep + stepInc
      }));
    })
  }

  handleCampaignAudienceSubmit = (values) => {
    const { match, history } = this.props;
    const { campaign } = this.state;
    return campaignsEmailService.replace(match.params.id, {
      ...(campaign || {}),
      ...values
    }).then(campaign => {
      this.setState({
        campaign: { ...campaign, Segments: values.Segments },
      });
      message.success("votre campagne a bien été enregistrée", 5);
    });
  }

  sendCampaign = async () => {
    const { history } = this.props;
    const { campaign } = this.state;
    const config = await configService.getConfig();
    if (!config.company || !config.addressLine1 || !config.addressPostalCode || !config.addressCity) {
      message.error(
        <span>
          Merci de saisir vos informations de contact ! <a href="/config">Aller sur ma page de configuration</a>
        </span>
      );
    } else {
      campaignsEmailService.send(campaign.id).then(res => {
        message.success(`votre campagne est planifiée pour l'envoi`, 5);
        history.push('/campaigns/email');
      });
    }
  }

  testCampaign = (emails) => {
    const { campaign } = this.state;
    campaignsEmailService.test(campaign.id, emails);
    message.success("le test a été envoyé", 5);
  }

  render() {
    const { currentStep, campaign, templates } = this.state;

    if (!campaign) return null;

    return (
      <Layout className={(currentStep === 1) ? "layout-xl" : ""}>
        <div className="Content-header" style={{ maxWidth: "992px", margin: "auto" }}>
          <div className="Content-header-actions">
            <Link to="/campaigns/email">
              <Button>voir toutes mes campagnes email</Button>
            </Link>
          </div>
          <h1><Icon className="mr-1" type="edit" />{(campaign && campaign.name) || 'Nouvelle campagne email'}</h1>
        </div>
        <Steps className="mt-2" current={ currentStep } style={{ padding: '0 2rem', maxWidth: "890px", marginLeft: "auto", marginRight: "auto" }}>
          <Step title="Configuration" />
          <Step title="Template" />
          <Step title="Audience" />
        </Steps>
        <div className="mt-2">
          {campaign.sentAt && (
            <Alert
              className="mt-4 mb-2"
              type="success"
              message={
                <Fragment>
                  Cette campagne a été envoyée {moment(campaign.sentAt).fromNow()}
                  <StatisticsModal
                    visible={this.state.statisticsModalVisible}
                    onCancel={() => this.setState({ statisticsModalVisible: false })}
                    campaignId={campaign.id} />
                  <Button style={{float: 'right'}} icon="area-chart" size="small" onClick={() => this.setState({ statisticsModalVisible: true })}>voir les statistiques</Button>
                </Fragment>
              }
              showIcon />
          )}
          {!campaign.sentAt && campaign.sendAt && (
            <Alert
              className="mt-4 mb-2"
              type="warning"
              message="Cette campagne est planifiée pour l'envoi"
              showIcon />
          )}
          {/* the key prop is there to force component reload each time the route change */}
          {(currentStep === 0) && campaign &&
            <CampaignEditorSettings
              key={campaign ? campaign.id : "new"}
              data={campaign}
              onCampaignSettingsSubmit={this.handleCampaignSettingsSubmit}
              onNext={() => this.changeStep(1)} />}
          {(currentStep === 1) && campaign && templates &&
            <CampaignEditorTemplate
              data={campaign && campaign.template}
              templates={templates}
              onCampaignTemplateSubmit={this.handleCampaignTemplateSubmit}
              onPrevious={() => this.changeStep(-1)}
              onNext={() => this.changeStep(1)} />}
          {(currentStep === 2) && campaign &&
            <CampaignEditorAudience
              onCampaignAudienceSubmit={this.handleCampaignAudienceSubmit}
              data={campaign}
              onSendCampaign={this.sendCampaign}
              onTestCampaign={this.testCampaign}
              onPrevious={() => this.changeStep(-1)} />}
        </div>
      </Layout>
    );
  }
}

export default CampaignEditor;
